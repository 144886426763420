import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";
import { produce } from "immer";
import { union, difference } from "lodash";

const initialState = {
  list: [],
  items: [],
  vsList: [],
  vsItems: [],
  isLoading: {
    list: false,
    items: false,
    vsList: false,
    vsItems: false
  },
  checkedCodes: []
};

export default handleActions(
  {
    [`${ActionTypes.BINDER_LIST_GET}_PENDING`]: state => ({
      ...state,
      // B2B_NVS_FR_2018-5653 検索機能が追加されたため、ここで検索する場合は既存の企業リストをクリアする必要があります。
      items: [],
      isLoading: {
        ...state.isLoading,
        list: true
      }
    }),
    [`${ActionTypes.BINDER_LIST_GET}_REJECTED`]: state =>
      produce(state, draft => {
        draft.isLoading.list = false;
      }),
    [`${ActionTypes.BINDER_LIST_GET}_FULFILLED`]: (state, action) =>
      produce(state, draft => {
        draft.list = action.payload.binderList;
        draft.isLoading.list = false;
      }),
    [`${ActionTypes.BINDER_ITEM_GET}_PENDING`]: state =>
      produce(state, draft => {
        draft.isLoading.items = true;
      }),
    [`${ActionTypes.BINDER_ITEM_GET}_REJECTED`]: state =>
      produce(state, draft => {
        draft.isLoading.items = false;
      }),
    [`${ActionTypes.BINDER_ITEM_GET}_FULFILLED`]: (state, action) =>
      produce(state, draft => {
        draft.items = action.payload.corpList;
        draft.isLoading.items = false;
      }),
    [`${ActionTypes.VS_BINDER_LIST_GET}_PENDING`]: state => ({
      ...state,
      // B2B_NVS_FR_2018-5653 検索機能が追加されたため、ここで検索する場合は既存の企業リストをクリアする必要があります。
      vsItems: [],
      isLoading: {
        ...state.isLoading,
        vsList: true
      }
    }),
    [`${ActionTypes.VS_BINDER_LIST_GET}_REJECTED`]: state =>
      produce(state, draft => {
        draft.isLoading.vsList = false;
      }),
    [`${ActionTypes.VS_BINDER_LIST_GET}_FULFILLED`]: (state, action) =>
      produce(state, draft => {
        draft.vsList = action.payload.binderList;
        draft.isLoading.vsList = false;
      }),
    [`${ActionTypes.VS_BINDER_ITEM_GET}_PENDING`]: state =>
      produce(state, draft => {
        draft.isLoading.vsItems = true;
      }),
    [`${ActionTypes.VS_BINDER_ITEM_GET}_REJECTED`]: state =>
      produce(state, draft => {
        draft.isLoading.vsItems = false;
      }),
    [`${ActionTypes.VS_BINDER_ITEM_GET}_FULFILLED`]: (state, action) =>
      produce(state, draft => {
        draft.vsItems = action.payload.corpList;
        draft.isLoading.vsItems = false;
      }),
    [ActionTypes.CHECKED_TOGGLE]: (state, action) => {
      if (action.payload.isAll) {
        return produce(state, draft => {
          draft.checkedCodes = action.payload.checked
            ? union(
                state.checkedCodes,
                state.items.map(item => item.corpCode)
              )
            : difference(
                state.checkedCodes,
                state.items.map(item => item.corpCode)
              );
        });
      }
      return produce(state, draft => {
        draft.checkedCodes = action.payload.checked
          ? union(state.checkedCodes, [action.payload.code])
          : state.checkedCodes.filter(code => code !== action.payload.code);
      });
    },
    [ActionTypes.VS_CHECKED_TOGGLE]: (state, action) => {
      if (action.payload.isAll) {
        return produce(state, draft => {
          draft.checkedCodes = action.payload.checked
            ? union(
                state.checkedCodes,
                state.vsItems.map(item => item.corpCode)
              )
            : difference(
                state.checkedCodes,
                state.vsItems.map(item => item.corpCode)
              );
        });
      }
      return produce(state, draft => {
        draft.checkedCodes = action.payload.checked
          ? union(state.checkedCodes, [action.payload.code])
          : state.checkedCodes.filter(code => code !== action.payload.code);
      });
    },
    [ActionTypes.CLEAR_CHECKED]: state => {
      return produce(state, draft => {
        draft.checkedCodes = [];
      });
    }
  },
  initialState
);

const handleParams = params => {
  const requestParams = {};
  if (params && params.keyword) {
    requestParams.keyword = params.keyword;
  }
  if (params && params.binderFilter) {
    requestParams.binderFilter = params.binderFilter;
  }
  if (params && params.categoryCode) {
    requestParams.categoryCode = params.categoryCode;
  }
  if (params && params.excludeBinderId) {
    requestParams.excludeBinderId = params.excludeBinderId;
  }
  return requestParams;
};

export const actionCreators = {
  getBinderList: createAction(ActionTypes.BINDER_LIST_GET, params => {
    return request("/common/binder", handleParams(params));
  }),
  getBinderItem: createAction(ActionTypes.BINDER_ITEM_GET, params =>
    request("/common/binder/list/corp", params)
  ),
  getVsBinderList: createAction(ActionTypes.VS_BINDER_LIST_GET, params => {
    return request("/common/binder", handleParams(params));
  }),
  getVsBinderItem: createAction(ActionTypes.VS_BINDER_ITEM_GET, params =>
    request("/common/binder/list/corp", params)
  ),
  toggleChecked: createAction(ActionTypes.CHECKED_TOGGLE),
  toggleVsChecked: createAction(ActionTypes.VS_CHECKED_TOGGLE),
  clearChecked: createAction(ActionTypes.CLEAR_CHECKED)
};
