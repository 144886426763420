import React, { useMemo } from "react";
import Checkbox from "components/Common/Checkbox";
import styles from "./style.scss";
import { Corp } from "models/CorpScreening";
import { ToolTip } from "features/ToolTip";
import LoadingSpinner from "components/Common/LoadingSpinner";

interface Item {
  companyCode: string;
  companyName: string;
  stkCode: string;
  source: string;
  companyKbn?: string;
}

interface Props {
  items: Corp[];
  selected: Item[];
  maxSelectableNumber: number;
  isLoading: boolean;
  onSelect: (corp: Item[]) => void;
  disabledCodes?: string[];
}

export const CorpList: React.FC<Props> = ({
  items,
  selected,
  maxSelectableNumber,
  isLoading,
  onSelect,
  disabledCodes = []
}) => {
  const isLimit = selected.length >= maxSelectableNumber;
  const isAllChecked = useMemo(() => {
    return (
      items.length > 0 &&
      items
        .filter(item => !disabledCodes.includes(item.companyCode))
        .every(item =>
          selected.find(corp => corp.companyCode === item.companyCode)
        )
    );
  }, [items, selected, disabledCodes]);

  const onClickAll = (checked: boolean) => {
    if (checked) {
      const newItems = items.filter(
        item =>
          !!!selected.find(corp => corp.companyCode === item.companyCode) &&
          !disabledCodes.includes(item.companyCode)
      );

      onSelect([
        ...selected,
        ...newItems.map(corp => ({
          companyCode: corp.companyCode,
          companyName: corp.companyName,
          stkCode: corp.kabushikiCode || "",
          source: corp.source || "",
          companyKbn: corp.companyKbn || ""
        }))
      ]);
      return;
    }
    onSelect(
      selected.filter(
        item => !!!items.find(corp => corp.companyCode === item.companyCode)
      )
    );
  };

  const onClickRow = (corp: Corp, checked: boolean) => {
    if (disabledCodes.includes(corp.companyCode)) return;
    if (checked) {
      onSelect([
        ...selected,
        {
          companyCode: corp.companyCode,
          companyName: corp.companyName,
          stkCode: corp.kabushikiCode || "",
          source: corp.source || "",
          companyKbn: corp.companyKbn || ""
        }
      ]);
      return;
    }
    onSelect(selected.filter(item => item.companyCode !== corp.companyCode));
  };

  const isSelectedEmpty = useMemo(() => {
    const selectedCompanyCodes = selected.map(item => item.companyCode);
    return !items.some(item => selectedCompanyCodes.includes(item.companyCode));
  }, [items, selected]);

  return (
    <div className={styles.container}>
      <div
        className={styles.title}
        onClick={() => {
          if (isLimit) {
            onClickAll(false);
            return;
          }
          onClickAll(!isAllChecked);
        }}
        data-testid="clickAll"
      >
        <div className={styles.checkbox}>
          {isLimit && isSelectedEmpty ? (
            <ToolTip
              position="right"
              content={
                <span>
                  項目選択数を{maxSelectableNumber}件以下にする
                  <br />
                  ことで選択可能になります。
                </span>
              }
              tooltipWidth={172}
            >
              <Checkbox isChecked={false} isActive={false} />
            </ToolTip>
          ) : (
            <Checkbox isChecked={isLimit || isAllChecked} />
          )}
        </div>
        <span>企業名</span>
      </div>
      <div className={styles.list}>
        {isLoading ? (
          <div className={styles.emptyContainer}>
            <LoadingSpinner size="L" />
          </div>
        ) : (
          items.map((item, index) => {
            const isChecked = Boolean(
              selected.find(corp => corp.companyCode === item.companyCode)
            );
            const isDisabled = disabledCodes.includes(item.companyCode);
            return (
              <div
                key={`corp-row-${index}`}
                className={styles.row}
                data-testid="corpRow"
                onClick={() => {
                  onClickRow(item, !isChecked);
                }}
              >
                <div className={styles.checkbox}>
                  {!isLimit || isChecked || isDisabled ? (
                    <Checkbox isChecked={isChecked} isActive={!isDisabled} />
                  ) : (
                    <ToolTip
                      position="right"
                      content={
                        <span>
                          項目選択数を{maxSelectableNumber}件以下にする
                          <br />
                          ことで選択可能になります。
                        </span>
                      }
                      tooltipWidth={172}
                    >
                      <Checkbox isChecked={false} isActive={false} />
                    </ToolTip>
                  )}
                </div>
                <div
                  className={`${styles.info} ${
                    disabledCodes.includes(item.companyCode)
                      ? styles.disabled
                      : ""
                  }`}
                >
                  <span className={styles.code}>{item.kabushikiCode}</span>
                  {item.companyName}
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};
