import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";

import { request } from "utils/apiClient/base";
import { isEmpty } from "utils/helper/common";

const initialState = {
  // 比較表関係
  comparisonHead: {},
  comparisonData: {},
  initialData: {},
  // 条件選択状態
  comparisonConditions: {
    userIndexValue: "", // 項目
    accKbnValue: "", // 期間単位
    ytConditionValue: "", // 有報
    scConditionValue: "", // 連結
    currencyConditionValue: "", // 通貨単位
    ltmValue: "0", // LTM
    expectValue: "0", // 日経予想
    listingCorpSelect: "", // 上場
    unlistedHolderCorpSelect: "", // 非上場有報
    unlistedTdbCorpSelect: "", // 非上場(TDB)
    unlistedCorpSelect: "", // 非上場(日経)
    industrycorptop: "", // 売上高上位
    industrycorptopCondition: "",
    northAmericaSelect: "", // 北米
    southAmericaSelect: "", // 南米
    europeSelect: "", // 欧州
    asiaSelect: "", // アジア
    mideastSelect: "", // 中東
    africaSelect: "", // アフリカ
    unlistedNorthAmericaSelect: "", // 北米非上場
    unlistedSouthAmericaSelect: "", // 南米非上場
    unlistedEuropeSelect: "", // 欧州非上場
    unlistedAsiaSelect: "", // アジア非上場
    unlistedMideastSelect: "", // 中東非上場
    unlistedAfricaSelect: "", // アフリカ非上場
    sortId: "", // ソート列番号
    sortFlag: "" // ソートの種類 up|down
  },
  // 企業数
  corpCounts: {
    africaCorpCount: "", // アフリカ
    mideastCorpCount: "", // 中東
    asiaCorpCount: "", // アジア
    europeCorpCount: "", // ヨーロッパ
    southAmericaCorpCount: "", // 南米
    northAmericaCorpCount: "", // 北米
    unlistedAfricaCorpCount: "", // アフリカ非上場
    unlistedMideastCorpCount: "", // 中東非上場
    unlistedAsiaCorpCount: "", // アジア非上場
    unlistedEuropeCorpCount: "", // ヨーロッパ非上場
    unlistedSouthAmericaCorpCount: "", // 南米非上場
    unlistedNorthAmericaCorpCount: "", // 北米非上場
    unlistedTdbCorpCount: "", // 非上場TDB
    unlistedCorpCount: "", // 非上場日経
    unlistedHolderCorpCount: "", // 有報
    listingCorpCount: "" // 上場
  },
  resultData: {},
  nikkeiCodeList: "", // 企業コードリスト
  nkCodeListTotal: "", // 全ての企業コードリスト
  contentId: "", // 比較表識別ID "corpcomparisonlist"
  isLoadingComparisonHead: false, // 条件ローディング中フラグ
  isLoadingComparisonData: false, // 検索ローディング中フラグ
  dlPeriodList: [],
  dlPeriodFrom: "",
  dlPeriodTo: "",
  // ユーザー指標関係
  indexTree: {},
  parentId: "",
  isAvailableIndex: "",
  selectedIndex: [],
  savedConditionList: [],
  selectedOption: "",
  isChildLoading: false,
  // チャート
  chartData: {},
  isLoadingChartCount: false,
  isLoadingChartData: false,
  zAxisList: [],
  // その他
  needsSearch: false, // 再検索フラグ
  isComparisonCleared: false, // 1000 件表示キャンセル時に内容非表示にするフラグ
  initFlag: false
};

export default handleActions(
  {
    /**
     * 比較表
     */
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => {
      const comparisonConditions = {
        africaSelect: "1",
        asiaSelect: "1",
        europeSelect: "1",
        listingCorpSelect: "1",
        mideastSelect: "1",
        northAmericaSelect: "1",
        southAmericaSelect: "1",
        unlistedAfricaSelect: "1",
        unlistedAsiaSelect: "1",
        unlistedCorpSelect: "1",
        unlistedEuropeSelect: "1",
        unlistedHolderCorpSelect: "1",
        unlistedMideastSelect: "1",
        unlistedNorthAmericaSelect: "1",
        unlistedSouthAmericaSelect: "1",
        unlistedTdbCorpSelect: "1"
      };
      const payload = action.payload;

      return {
        ...state,
        initialData: payload,
        nikkeiCodeList: payload.nikkeiCodeList,
        nkCodeListTotal: payload.nikkeiCodeList,
        contentId: payload.contentId,
        comparisonConditions: {
          ...state.comparisonConditions,
          ...comparisonConditions
        },
        corpCounts: {
          africaCorpCount: payload.africaNkcodeCount,
          asiaCorpCount: payload.asiaPacificNkcodeCount,
          europeCorpCount: payload.europeNkcodeCount,
          listingCorpCount: payload.listingNkcodeCount,
          mideastCorpCount: payload.mideastNkcodeCount,
          northAmericaCorpCount: payload.northAmericaNkcodeCount,
          southAmericaCorpCount: payload.southAmericaNkcodeCount,
          unlistedAfricaCorpCount: payload.unlistedAfricaNkcodeCount,
          unlistedMideastCorpCount: payload.unlistedMideastNkcodeCount,
          unlistedAsiaCorpCount: payload.unlistedAsiaPacificNkcodeCount,
          unlistedEuropeCorpCount: payload.unlistedEuropeNkcodeCount,
          unlistedSouthAmericaCorpCount:
            payload.unlistedSouthAmericaNkcodeCount,
          unlistedNorthAmericaCorpCount:
            payload.unlistedNorthAmericaNkcodeCount,
          unlistedCorpCount: payload.unListedNkcodeCount,
          unlistedHolderCorpCount: payload.unListedHolderNkcodeCount,
          unlistedTdbCorpCount: payload.unListedTdbCount
        },
        zAxisList: payload.labelValueDtoList08,
        isComparisonCleared: false
      };
    },
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.COMPARISON_HEAD_GET}_PENDING`]: state => ({
      ...state,
      isLoadingComparisonHead: true
    }),
    [`${ActionTypes.COMPARISON_HEAD_GET}_FULFILLED`]: (state, action) => {
      let userIndexValue;
      if (
        !isEmpty(userIndexValue) &&
        action.payload.userIndexGroupListDto.userIndexGroupListOfBasic.find(
          index => index.value == action.meta.userIndexValue
        )
      ) {
        // 選択中のユーザ指標
        userIndexValue = action.meta.userIndexValue;
      } else {
        userIndexValue =
          action.payload.userIndexGroupListDto.userIndexGroupListOfBasic[0]
            .value;
      }

      return {
        ...state,
        comparisonHead: action.payload,
        comparisonConditions: {
          ...state.comparisonConditions,
          userIndexValue: userIndexValue,
          accKbnValue: action.payload.accKbnList[0].value,
          ytConditionValue: action.payload.ytCommonConditionList[0].value,
          scConditionValue: action.payload.scCommonConditionList[0].value,
          currencyConditionValue: action.payload.currencyList[0].value
        },
        isLoadingComparisonHead: false
      };
    },
    [`${ActionTypes.COMPARISON_HEAD_GET}_REJECTED`]: state => ({
      ...state,
      isLoadingComparisonHead: false
    }),
    [`${ActionTypes.GET_ITEMS_PER_PAGE}_FULFILLED`]: (state, action) => ({
      ...state,
      count: action.payload
    }),
    [`${ActionTypes.GET_COMPARISON_DATA}_PENDING`]: state => ({
      ...state,
      isLoadingComparisonData: true,
      initFlag: false
    }),
    [`${ActionTypes.GET_COMPARISON_DATA}_FULFILLED`]: (state, action) => {
      const isCheckboxesActive =
        action.payload.selectItemDisabled == "1" ||
        action.payload.selectItemDisabled == "3";
      return {
        ...state,
        comparisonData: action.payload,
        nikkeiCodeList: action.payload.currentNkCodeList,
        nkCodeListTotalList: action.payload.nkCodeListTotalList,
        comparisonConditions: {
          ...state.comparisonConditions,
          // チェックボックス群が無効になった場合、選択状態解除
          ltmValue: isCheckboxesActive
            ? state.comparisonConditions.ltmValue
            : "0",
          expectValue: isCheckboxesActive
            ? state.comparisonConditions.expectValue
            : "0"
        },
        corpCounts: {
          africaCorpCount: action.payload.africaNkcodeCount,
          asiaCorpCount: action.payload.asiaPacificNkcodeCount,
          europeCorpCount: action.payload.europeNkcodeCount,
          listingCorpCount: action.payload.listingNkcodeCount,
          mideastCorpCount: action.payload.mideastNkcodeCount,
          northAmericaCorpCount: action.payload.northAmericaNkcodeCount,
          southAmericaCorpCount: action.payload.southAmericaNkcodeCount,
          unlistedAfricaCorpCount: action.payload.unlistedAfricaNkcodeCount,
          unlistedMideastCorpCount: action.payload.unlistedMideastNkcodeCount,
          unlistedAsiaCorpCount: action.payload.unlistedAsiaPacificNkcodeCount,
          unlistedEuropeCorpCount: action.payload.unlistedEuropeNkcodeCount,
          unlistedSouthAmericaCorpCount:
            action.payload.unlistedSouthAmericaNkcodeCount,
          unlistedNorthAmericaCorpCount:
            action.payload.unlistedNorthAmericaNkcodeCount,
          unlistedCorpCount: action.payload.unListedNkcodeCount,
          unlistedHolderCorpCount: action.payload.unListedHolderNkcodeCount,
          unlistedTdbCorpCount: action.payload.unListedTdbCount
        },
        isLoadingComparisonData: false,
        chartData: {} // チャートデータクリア
      };
    },
    [`${ActionTypes.GET_COMPARISON_DATA}_REJECTED`]: state => ({
      ...state,
      isLoadingComparisonData: false,
      chartData: {} // チャートデータクリア
    }),
    [`${ActionTypes.CHANGE_LIST}_FULFILLED`]: (state, action) => {
      return {
        ...state,
        comparisonData: {},
        nikkeiCodeList: action.payload.nikkeiCodeList,
        nkCodeListTotal: action.payload.nikkeiCodeList
      };
    },
    [ActionTypes.SELECTED_CHANGE]: (state, action) => ({
      ...state,
      comparisonConditions: {
        ...state.comparisonConditions,
        ...action.payload
      }
    }),
    [`${ActionTypes.SORT_COMPARISON_DATA}_PENDING`]: state => ({
      ...state,
      isLoadingComparisonData: true
    }),
    [`${ActionTypes.SORT_COMPARISON_DATA}_FULFILLED`]: (state, action) => ({
      ...state,
      comparisonData: {
        ...state.comparisonData,
        tableData: action.payload.tableData
      },
      nikkeiCodeList: action.payload.currentNkCodeList,
      nkCodeListTotalList: action.payload.nkCodeListTotalList,
      isLoadingComparisonData: false,
      chartData: {} // チャートデータクリア
    }),
    [`${ActionTypes.SORT_COMPARISON_DATA}_REJECTED`]: state => ({
      ...state,
      isLoadingComparisonData: false,
      chartData: {} // チャートデータクリア
    }),
    [`${ActionTypes.DELETE_ROW}_PENDING`]: state => ({
      ...state,
      isLoadingComparisonData: true
    }),
    [`${ActionTypes.DELETE_ROW}_FULFILLED`]: (state, action) => ({
      ...state,
      comparisonData: action.payload,
      nikkeiCodeList: action.payload.currentNkCodeList,
      nkCodeListTotal: action.payload.nkCodeListTotalList,
      isLoadingComparisonData: false,
      chartData: {} // チャートデータクリア
    }),
    [`${ActionTypes.DELETE_ROW}_REJECTED`]: state => ({
      ...state,
      isLoadingComparisonData: false,
      chartData: {} // チャートデータクリア
    }),
    [ActionTypes.SEARCH_DATA_CLEAR]: state => ({
      ...state,
      comparisonData: {}, // クリア
      comparisonConditions: {}, // 選択、入力状態をクリア
      isComparisonCleared: true
    }),

    /**
     * ユーザ指標編集
     */
    [`${ActionTypes.INDEX_TREE_ROOT_GET}_FULFILLED`]: (state, action) => {
      const newIndexTree = action.payload;
      let selectedIndex = [];
      action.payload.userSetDetailList.forEach(detail => {
        // 通常の項目
        if (!isEmpty(detail.scrGroup1)) {
          selectedIndex.push({ ...detail });
        } else {
          // グループ項目
          const lastPos = selectedIndex.length - 1;
          if (isEmpty(selectedIndex[lastPos].groupList))
            selectedIndex[lastPos].groupList = [];
          // 条件を選択したときと同じデータに整形
          selectedIndex[lastPos].groupList.push(
            `${detail.screeningId}_${detail.hierarchyName}`
          );
        }
      });
      if (action.meta.kensakuName !== undefined && selectedIndex.length === 0) {
        selectedIndex = state.selectedIndex;
      }
      if (!isEmpty(action.meta.kensakuName)) {
        // 検索文字列が空でない場合に全て open 状態にする
        const setOpenToAllChildState = treeDataList => {
          return treeDataList.map(item => {
            if (!isEmpty(item.child)) {
              item.childExistFlag = true;
              item.isOpen = true;
              item.childLoaded = true;
              setOpenToAllChildState(item.child);
            }
            return item;
          });
        };
        newIndexTree.treeDataList = setOpenToAllChildState(
          newIndexTree.treeDataList
        );
      }
      return {
        ...state,
        indexTree: newIndexTree,
        selectedOption: isEmpty(state.selectedOption)
          ? action.payload.userSelect[0].value
          : state.selectedOption,
        selectedIndex: selectedIndex
      };
    },
    [`${ActionTypes.INDEX_TREE_CHILD_GET}_PENDING`]: (state, action) => ({
      ...state,
      parentId: action.meta.parentId, //metaから親IDを取得、state.parentIdに保存,
      isChildLoading: true
    }),
    [`${ActionTypes.INDEX_TREE_CHILD_GET}_FULFILLED`]: (state, action) => {
      // 子を追加したい親ノードのID(state.parentId)を探して追加
      const recursiveSearchParent = (tree, parentId) => {
        for (const key in tree) {
          const node = tree[key];
          if (node.id === parentId) {
            node.child = action.payload.treeDataList;
            node.childLoaded = true;
            node.isOpen = true;
            break;
          }
          if (node.childLoaded) {
            recursiveSearchParent(node.child, state.parentId);
          }
        }
      };
      const newIndexTree = { ...state.indexTree };
      recursiveSearchParent(newIndexTree.treeDataList, state.parentId);
      return {
        ...state,
        indexTree: newIndexTree,
        isChildLoading: false
      };
    },
    [`${ActionTypes.INDEX_AVAILABILITY_GET}_FULFILLED`]: state => state,
    [`${ActionTypes.INDEX_ADD}_FULFILLED`]: (state, action) => {
      const newSelectedIndex = [...state.selectedIndex];
      // 存在、上限チェック
      const matchedIndex = newSelectedIndex.find(
        index => index.screeningId == action.payload.screeningId
      );
      if (
        !matchedIndex &&
        parseInt(state.indexTree.maxCount) > newSelectedIndex.length
      ) {
        newSelectedIndex.push(action.payload);
      }
      return {
        ...state,
        selectedIndex: newSelectedIndex
      };
    },
    [ActionTypes.INDEX_REMOVE]: (state, action) => {
      let newSelectedIndex = [...state.selectedIndex];
      newSelectedIndex = newSelectedIndex.filter(
        item => item.screeningId !== action.payload
      );

      return {
        ...state,
        selectedIndex: newSelectedIndex
      };
    },
    [ActionTypes.INDEX_REMOVE_ALL]: state => ({
      ...state,
      selectedIndex: [],
      selectedOption: state.indexTree.userSelect[0].value
    }),
    [ActionTypes.INDEX_UPDATE]: (state, action) => ({
      ...state,
      selectedIndex: action.payload
    }),
    [`${ActionTypes.SAVED_CONDITION_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      savedConditionList: action.payload.userSetDisplayItemList
    }),
    [`${ActionTypes.SAVED_CONDITION_DELETE}_FULFILLED`]: (state, action) => ({
      ...state,
      savedConditionList: action.payload.userSetDisplayItemList,
      selectedIndex: [],
      selectedOption: state.indexTree.userSelect[0].value
    }),
    [`${ActionTypes.SAVED_CONDITION_UPDATE}_FULFILLED`]: (state, action) => ({
      ...state,
      savedConditionList: action.payload.userSetDisplayItemList,
      selectedIndex: [],
      selectedOption: state.indexTree.userSelect[0].value
    }),
    [`${ActionTypes.CONDITION_SET}_FULFILLED`]: state => ({
      ...state,
      // 条件の登録が完了したら空にリセットしてモーダルを閉じる
      indexTree: {}
    }),
    [`${ActionTypes.SAVED_CONDITION_DETAIL_GET}_FULFILLED`]: (
      state,
      action
    ) => {
      // 表示用にレスポンスをパースする
      const selectedIndex = [];
      action.payload.userSetDetailList.forEach(detail => {
        // 通常の項目
        if (!isEmpty(detail.scrGroup1)) {
          selectedIndex.push({ ...detail });
        } else {
          // グループ項目
          const lastPos = selectedIndex.length - 1;
          if (isEmpty(selectedIndex[lastPos].groupList))
            selectedIndex[lastPos].groupList = [];
          // 条件を選択したときと同じデータに整形
          selectedIndex[lastPos].groupList.push(
            `${detail.screeningId}_${detail.hierarchyName}`
          );
        }
      });
      return {
        ...state,
        selectedIndex,
        selectedOption: action.meta.id
      };
    },
    [`${ActionTypes.DOWNLOAD_PERIOD_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      dlPeriodList: action.payload.periodList,
      dlPeriodFrom: action.payload.periodFrom,
      // レスポンスにToの初期値なし
      dlPeriodTo: action.payload.periodList[0].value
    }),
    [ActionTypes.DOWNLOAD_PERIOD_SET]: (state, action) => ({
      ...state,
      [action.meta.key]: action.payload
    }),
    [`${ActionTypes.SAVED_CONDITION_SELECT_LIST_GET}_FULFILLED`]: (
      state,
      action
    ) => ({
      ...state,
      indexTree: {
        ...state.indexTree,
        userSelect: action.payload
      },
      selectedOption: action.payload[0].value
    }),
    [ActionTypes.INDEX_ALL_CLEAR]: state => ({
      ...state,
      indexTree: {},
      parentId: "",
      isAvailableIndex: "",
      selectedIndex: [],
      savedConditionList: [],
      selectedOption: ""
    }),

    /**
     * チャート
     */
    // チャート選択件数制御数データ取得
    [`${ActionTypes.CHART_COUNT_GET}_PENDING`]: state => ({
      ...state,
      isLoadingChartCount: true
    }),
    [`${ActionTypes.CHART_COUNT_GET}_FULFILLED`]: state => ({
      ...state,
      isLoadingChartCount: false
    }),
    // チャートデータ取得
    [`${ActionTypes.CHART_DATA_GET}_PENDING`]: state => ({
      ...state,
      isLoadingChartData: true
    }),
    [`${ActionTypes.CHART_DATA_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      chartData: action.payload,
      isLoadingChartData: false
    }),
    [`${ActionTypes.CHART_DATA_UPDATE}_PENDING`]: state => ({
      ...state,
      isLoadingChartData: true
    }),
    [`${ActionTypes.CHART_DATA_UPDATE}_FULFILLED`]: (state, action) => ({
      ...state,
      chartData: {
        ...state.chartData,
        chart01Str: action.payload.chart01Str,
        chart02Str: action.payload.chart02Str,
        chart03Str: action.payload.chart03Str,
        chart04Str: action.payload.chart04Str
      },
      isLoadingChartData: false
    }),
    [`${ActionTypes.CHART_DATA_UPDATE}_REJECTED`]: state => ({
      ...state,
      isLoadingChartData: false
    }),
    [ActionTypes.CHART_DATA_CLEAR]: state => ({
      ...state,
      chartData: {}
    }),

    /**
     * その他
     */
    [ActionTypes.SEARCH_FLAG_SET]: (state, action) => ({
      ...state,
      needsSearch: true,
      // 初回リクエストのみ init を付与する必要があるため、 payload から受けとる
      initFlag: action.payload && action.payload.initFlag ? true : false
    }),
    [ActionTypes.SEARCH_FLAG_RESET]: state => ({
      ...state,
      needsSearch: false
    }),
    [ActionTypes.SORT_INFO_UNSET]: state => ({
      ...state,
      comparisonConditions: {
        ...state.comparisonConditions,
        sortId: "",
        sortFlag: ""
      }
    })
  },
  initialState
);

const userSetKbn = 2;
const tabId = 4;
const displayKbn = 2;

export const actionCreators = {
  // 比較表関係
  initPage: createAction(
    ActionTypes.PAGE_INIT,
    params => request(620, params.payload),
    params => params.meta
  ),
  getComparisonHead: createAction(
    ActionTypes.COMPARISON_HEAD_GET,
    params =>
      request(595, {
        ...params,
        tabId,
        displayKbn,
        userSetKbn
      }),
    (p, userIndexValue) => ({ userIndexValue })
  ),
  getItemsPerPage: createAction(ActionTypes.GET_ITEMS_PER_PAGE, params =>
    request(613, params)
  ),
  search: createAction(ActionTypes.GET_COMPARISON_DATA, params =>
    request(597, params)
  ),
  deleteRow: createAction(ActionTypes.DELETE_ROW, params =>
    request(597, params)
  ),
  clearSearchData: createAction(ActionTypes.SEARCH_DATA_CLEAR),
  changeList: createAction(
    ActionTypes.CHANGE_LIST,
    params => request(621, params),
    (_, meta) => meta
  ),
  changeSelected: createAction(ActionTypes.SELECTED_CHANGE),
  sort: createAction(ActionTypes.SORT_COMPARISON_DATA, params =>
    request(609, params)
  ),
  getDownloadPeriod: createAction(ActionTypes.DOWNLOAD_PERIOD_GET, params =>
    request(616, params)
  ),
  setDownloadPeriod: createAction(
    ActionTypes.DOWNLOAD_PERIOD_SET,
    period => period,
    (period, key) => ({ key })
  ),

  // ユーザ指標関係
  getIndexTreeRoot: createAction(
    ActionTypes.INDEX_TREE_ROOT_GET,
    params =>
      request(599, {
        userSetKbn,
        flg: 3, // 現行通りの固定値
        ...params
      }),
    params => ({ kensakuName: params.kensakuName })
  ),
  getIndexTreeChild: createAction(
    ActionTypes.INDEX_TREE_CHILD_GET,
    params => request(606, params),
    (p, meta) => ({ parentId: meta.currentId }) // metaに子を取得したい親ノードのIDをもたせる
  ),
  getIndexAvailability: createAction(
    ActionTypes.INDEX_AVAILABILITY_GET,
    params => request(605, params)
  ),
  addIndex: createAction(
    ActionTypes.INDEX_ADD,
    params => request(607, params),
    params => params.meta
  ),
  removeIndex: createAction(ActionTypes.INDEX_REMOVE),
  removeAllIndex: createAction(ActionTypes.INDEX_REMOVE_ALL),
  getSavedConditionList: createAction(ActionTypes.SAVED_CONDITION_GET, params =>
    request(600, { userSetKbn, ...params })
  ),
  deleteSavedCondition: createAction(
    ActionTypes.SAVED_CONDITION_DELETE,
    params => request(602, { userSetKbn, ...params })
  ),
  updateSavedCondition: createAction(
    ActionTypes.SAVED_CONDITION_UPDATE,
    params => request(601, { userSetKbn, ...params })
  ),
  getSavedConditionDetail: createAction(
    ActionTypes.SAVED_CONDITION_DETAIL_GET,
    params => request(604, params),
    (p, id) => ({ id })
  ),
  setCondition: createAction(ActionTypes.CONDITION_SET, params =>
    request(603, { userSetKbn, ...params })
  ),
  updateIndex: createAction(ActionTypes.INDEX_UPDATE),
  getSavedConditionSelectList: createAction(
    ActionTypes.SAVED_CONDITION_SELECT_LIST_GET,
    params => request(608, { userSetKbn, ...params })
  ),
  clearIndexAll: createAction(ActionTypes.INDEX_ALL_CLEAR),

  // チャート関係
  getChartCount: createAction(ActionTypes.CHART_COUNT_GET, params =>
    request(614, params)
  ),
  getChartData: createAction(ActionTypes.CHART_DATA_GET, params =>
    request(629, params)
  ),
  updateChartData: createAction(ActionTypes.CHART_DATA_UPDATE, params =>
    request(630, params)
  ),
  clearChartData: createAction(ActionTypes.CHART_DATA_CLEAR),

  // その他
  setSearchFlag: createAction(ActionTypes.SEARCH_FLAG_SET),
  resetSearchFlag: createAction(ActionTypes.SEARCH_FLAG_RESET),
  unsetSortInfo: createAction(ActionTypes.SORT_INFO_UNSET)
};
