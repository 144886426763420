import React, { useRef } from "react";
import Suggest from "components/Common/Suggest";
import { CorporationTable } from "../../CorporationTable";
import styles from "./style.scss";
import SectionLoading from "components/Common/SectionLoading";

interface Item {
  companyCode: string;
  companyName: string;
  stkCode: string;
  source: string;
  companyKbn?: string;
}

interface Props {
  items: Item[] | null;
  selectedCorp: Item[];
  type: string;
  maxSelectableNumber: number;
  isLoading: boolean;
  searchCorp: ({
    companyName,
    suggestFlag,
    suggestMeta,
    corpIndustyKbn
  }: {
    companyName: string;
    suggestFlag?: boolean;
    suggestMeta?: string | undefined;
    corpIndustyKbn?: string | undefined;
  }) => void;
  onSelect: (corp: Item[]) => void;
  ingoreInit?: boolean;
  defaultKeyword?: string;
  disabledCodes?: string[];
}

export const CorpName: React.FC<Props> = ({
  items,
  selectedCorp,
  type,
  maxSelectableNumber,
  isLoading,
  searchCorp,
  onSelect,
  ingoreInit = false,
  defaultKeyword = "",
  disabledCodes = []
}) => {
  const isInit = useRef(true);

  return (
    <div className={styles.container}>
      <Suggest
        type={type}
        placeholder="企業名"
        onEnterWithKeyword={(keyword: string) => {
          searchCorp({
            companyName: keyword,
            suggestFlag: false
          });
          isInit.current = false;
        }}
        onSelectSuggest={(item: { keyword: string; meta: string }) => {
          searchCorp({
            companyName: item.keyword,
            suggestFlag: true,
            suggestMeta: item.meta
          });
          isInit.current = false;
        }}
        isTopSearch
        isKeepKeyword
        defaultKeyword={defaultKeyword}
      />
      {(!isInit.current || ingoreInit) && (
        <SectionLoading isLoading={isLoading}>
          <div className={styles.tableContainer}>
            {!isLoading && (
              <CorporationTable
                items={items || []}
                selectedCorp={selectedCorp}
                maxSelectableNumber={maxSelectableNumber}
                onSelect={onSelect}
                disabledCodes={disabledCodes}
              />
            )}
          </div>
        </SectionLoading>
      )}
    </div>
  );
};
