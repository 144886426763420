import { useCallback, useState } from "react";
import { Response } from "models/api/binder/Personal";
import { useDispatch } from "react-redux";
import { useReduxState } from "hooks/useReduxState";
import {
  setNikkeiBinderItems,
  changeIsAdding,
  setNikkeiTotalCount,
  addNikkeiBinderItems,
  setNikkeiOffset,
  setNikkeiLimit,
  setNikkeiListLimit,
  setNikkeiSortValue,
  changeIsLoading,
  setNikkeiKeyword,
  setIsSearch
} from "modules/binders/list/actions";
import { setNikkeiCardViewMode } from "modules/binders/saved/actions";
import { useToast } from "hooks/useToast";
import { request } from "utils/apiClient";
import { LAZY_LOAD_LIMIT } from "features/BinderPage/List";
import { trackAction } from "utils/helper/analytics";

type ErrorType = {
  message: string;
  body: {
    code: number;
    details: {
      apiErrorCode: string;
      message: string;
      stackTrace?: string;
      errorType?: string;
    }[];
  };
};

export const useNikkeiBinder = () => {
  const [isError, setIsError] = useState(false);
  const binderItems = useReduxState(s => s.binders.list.nikkei);
  const isLoading = useReduxState(s => s.binders.list.isLoading);
  const totalCount = useReduxState(s => s.binders.list.nikkeiTotalCount);
  const isAdding = useReduxState(s => s.binders.list.isAdding);
  const dispatch = useDispatch();
  const toast = useToast();
  const cardViewMode = useReduxState(s => s.binders.saved.nikkeiCardViewMode);
  const offset = useReduxState(s => s.binders.list.nikkeiOffset);
  const limit = useReduxState(s => s.binders.list.nikkeiLimit);
  const listLimit = useReduxState(s => s.binders.list.nikkeiListLimit);
  const sortValue = useReduxState(s => s.binders.list.nikkeiSortValue);
  const nikkeiKeyword = useReduxState(s => s.binders.list.nikkeiKeyword);
  const isSearch = useReduxState(s => s.binders.list.isSearch);

  const fetchErrorHandle = useCallback(
    (error: ErrorType) => {
      const errors = [];
      if (error && error.body && error.body.details) {
        for (const detail of error.body.details) {
          errors.push(detail.message);
        }
      }
      toast.setErrors(
        error.message === "GATEWAY_TIMEOUT"
          ? "コレクションの読み込みがタイムアウトしました"
          : errors
      );
      setIsError(error.message === "GATEWAY_TIMEOUT");
    },
    [toast]
  );

  const fetchNikkeiBinders = useCallback(async () => {
    if (nikkeiKeyword && nikkeiKeyword.length > 500) {
      return;
    }
    const setLimit = cardViewMode ? LAZY_LOAD_LIMIT.card : limit;
    dispatch(changeIsLoading(true));
    try {
      dispatch(setNikkeiOffset(0));
      dispatch(setNikkeiLimit(setLimit));
      dispatch(setIsSearch(!!nikkeiKeyword));
      dispatch(
        setNikkeiSortValue(
          !nikkeiKeyword && sortValue === "3" ? "1" : sortValue
        )
      );
      const response = await request<Response>("/binder/nikkei", {
        needFeedArticles: location.pathname === "/" || cardViewMode ? 1 : 0,
        offset: 0,
        limit: setLimit,
        sortKbn: !nikkeiKeyword && sortValue === "3" ? "1" : sortValue,
        keyword: nikkeiKeyword
      });
      dispatch(setNikkeiBinderItems(response.binderList));
      dispatch(setNikkeiTotalCount(response.totalCount));
    } catch (error) {
      fetchErrorHandle(error as ErrorType);
    } finally {
      dispatch(changeIsLoading(false));
    }
  }, [
    cardViewMode,
    dispatch,
    fetchErrorHandle,
    nikkeiKeyword,
    sortValue,
    limit
  ]);

  const addNikkeiBinders = useCallback(async () => {
    if (nikkeiKeyword && nikkeiKeyword.length > 500) {
      return;
    }
    if (offset + limit < totalCount) {
      dispatch(changeIsAdding(true));
      dispatch(setNikkeiOffset(offset + limit));
      const response = await request<Response>("/binder/nikkei", {
        needFeedArticles: cardViewMode ? 1 : 0,
        limit,
        offset: offset + limit,
        sortKbn: sortValue,
        keyword: nikkeiKeyword
      });
      dispatch(addNikkeiBinderItems(response.binderList));
      dispatch(setNikkeiTotalCount(response.totalCount));
      dispatch(changeIsAdding(false));
    }
  }, [
    cardViewMode,
    dispatch,
    limit,
    nikkeiKeyword,
    offset,
    sortValue,
    totalCount
  ]);

  const sortNikkeiBinders = async (sortKbn: string) => {
    if (nikkeiKeyword && nikkeiKeyword.length > 500) {
      return;
    }
    dispatch(changeIsLoading(true));
    try {
      dispatch(setNikkeiSortValue(sortKbn));
      dispatch(setNikkeiOffset(0));
      dispatch(setIsSearch(!!nikkeiKeyword));
      dispatch(
        setNikkeiSortValue(!nikkeiKeyword && sortKbn === "3" ? "1" : sortKbn)
      );
      const response = await request<Response>("/binder/nikkei", {
        needFeedArticles: cardViewMode ? 1 : 0,
        sortKbn,
        offset: 0,
        limit,
        keyword: nikkeiKeyword
      });
      dispatch(setNikkeiBinderItems(response.binderList));
      dispatch(setNikkeiTotalCount(response.totalCount));
    } catch (error) {
      fetchErrorHandle(error as ErrorType);
    } finally {
      dispatch(changeIsLoading(false));
    }
  };

  const setCardViewMode = (isCardMode: boolean) => {
    dispatch(setNikkeiCardViewMode(isCardMode));
  };

  const switchCardViewMode = useCallback(
    async (cardMode: boolean) => {
      if (nikkeiKeyword && nikkeiKeyword.length > 500) {
        return;
      }
      try {
        dispatch(changeIsLoading(true));
        const changedLimit = cardMode ? LAZY_LOAD_LIMIT.card : listLimit;
        if (!cardMode) {
          dispatch(setNikkeiListLimit(changedLimit));
        }
        dispatch(setNikkeiLimit(changedLimit));
        dispatch(setNikkeiCardViewMode(cardMode));
        dispatch(setNikkeiOffset(0));
        dispatch(setIsSearch(!!nikkeiKeyword));
        dispatch(
          setNikkeiSortValue(
            !nikkeiKeyword && sortValue === "3" ? "1" : sortValue
          )
        );
        const response = await request<Response>("/binder/nikkei", {
          needFeedArticles: cardMode ? 1 : 0,
          sortKbn: !nikkeiKeyword && sortValue === "3" ? "1" : sortValue,
          keyword: nikkeiKeyword,
          limit: changedLimit
        });
        dispatch(setNikkeiBinderItems(response.binderList));
        dispatch(setNikkeiTotalCount(response.totalCount));
      } catch (error) {
        fetchErrorHandle(error as ErrorType);
      } finally {
        dispatch(changeIsLoading(false));
      }
    },
    [dispatch, nikkeiKeyword, sortValue, listLimit, fetchErrorHandle]
  );

  const changePage = useCallback(
    async (offset: number, limit: number) => {
      if (nikkeiKeyword && nikkeiKeyword.length > 500) {
        return;
      }
      try {
        dispatch(setNikkeiOffset(offset));
        dispatch(setNikkeiLimit(limit));
        dispatch(setIsSearch(!!nikkeiKeyword));
        dispatch(
          setNikkeiSortValue(
            !nikkeiKeyword && sortValue === "3" ? "1" : sortValue
          )
        );
        dispatch(changeIsLoading(true));
        const response = await request<Response>("/binder/nikkei", {
          needFeedArticles: cardViewMode ? 1 : 0,
          offset,
          limit,
          keyword: nikkeiKeyword,
          sortKbn: !nikkeiKeyword && sortValue === "3" ? "1" : sortValue
        });
        dispatch(setNikkeiBinderItems(response.binderList));
        dispatch(setNikkeiTotalCount(response.totalCount));
      } catch (error) {
        fetchErrorHandle(error as ErrorType);
      } finally {
        dispatch(changeIsLoading(false));
      }
    },
    [cardViewMode, dispatch, nikkeiKeyword, sortValue, fetchErrorHandle]
  );

  const changeLimit = useCallback(
    async (limit: number) => {
      if (nikkeiKeyword && nikkeiKeyword.length > 500) {
        return;
      }
      try {
        dispatch(setNikkeiLimit(limit));
        dispatch(setNikkeiListLimit(limit));
        dispatch(setNikkeiOffset(0));
        dispatch(setIsSearch(!!nikkeiKeyword));
        dispatch(
          setNikkeiSortValue(
            !nikkeiKeyword && sortValue === "3" ? "1" : sortValue
          )
        );
        dispatch(changeIsLoading(true));
        const response = await request<Response>("/binder/nikkei", {
          needFeedArticles: cardViewMode ? 1 : 0,
          offset: 0,
          limit,
          keyword: nikkeiKeyword,
          sortKbn: !nikkeiKeyword && sortValue === "3" ? "1" : sortValue
        });
        dispatch(setNikkeiBinderItems(response.binderList));
        dispatch(setNikkeiTotalCount(response.totalCount));
      } catch (error) {
        fetchErrorHandle(error as ErrorType);
      } finally {
        dispatch(changeIsLoading(false));
      }
    },
    [cardViewMode, dispatch, nikkeiKeyword, sortValue, fetchErrorHandle]
  );

  const onKeywordSearch = useCallback(
    async (keyword: string) => {
      if (keyword && keyword.length > 500) {
        return;
      }
      try {
        trackAction("myCollectionSearchKeyword", keyword);
        dispatch(setNikkeiKeyword(keyword));
        dispatch(setIsSearch(!!keyword));
        dispatch(setNikkeiSortValue(keyword ? "3" : "1"));
        dispatch(setNikkeiOffset(0));
        dispatch(changeIsLoading(true));
        const response = await request<Response>("/binder/nikkei", {
          needFeedArticles: cardViewMode ? 1 : 0,
          offset: 0,
          limit,
          sortKbn: keyword ? "3" : "1",
          keyword
        });
        dispatch(setNikkeiBinderItems(response.binderList));
        dispatch(setNikkeiTotalCount(response.totalCount));
      } catch (error) {
        fetchErrorHandle(error as ErrorType);
      } finally {
        dispatch(changeIsLoading(false));
      }
    },
    [cardViewMode, dispatch, limit, fetchErrorHandle]
  );

  return {
    isLoading,
    sortValue,
    binderItems,
    isError,
    fetchNikkeiBinders,
    sortNikkeiBinders,
    cardViewMode,
    totalCount,
    setCardViewMode,
    addNikkeiBinders,
    limit,
    offset,
    isAdding,
    switchCardViewMode,
    changePage,
    changeLimit,
    onKeywordSearch,
    nikkeiKeyword,
    setNikkeiKeyword,
    isSearch
  };
};
