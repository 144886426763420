import React, { useEffect, useRef } from "react";
import styles from "./style.scss";
import Modal from "components/Common/Modal";
import Button from "components/Common/Button/Text";
import { trackAction } from "utils/helper/analytics";

interface Props {
  headline: string;
  fare: string;
  samplePath: string;
  open: () => void;
  onClose: () => void;
}

interface ModalRef {
  open: () => void;
  close: () => void;
}

const PaidConfirm: React.FunctionComponent<Props> = props => {
  const modal = useRef<ModalRef | null>(null);

  useEffect(() => {
    modal.current && modal.current.open();
  }, []);

  return (
    <Modal
      ref={(el: ModalRef) => (modal.current = el)}
      title="有料コンテンツ閲覧の確認"
      modalClassName={styles.modal}
      onClose={() => props.onClose()}
    >
      <div className={styles.container}>
        <div className={styles.label}>見出し</div>
        <div>{props.headline}</div>
        <div className={styles.label}>料金</div>
        <div className={styles.fee}>{props.fare}</div>
        {props.samplePath && (
          <div className={styles.simpleWrapper}>
            <div className={styles.label}>購入前にサンプルを確認できます</div>
            <a
              href={`https://${location.host}${props.samplePath}`}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.button}
              onClick={() => {
                trackAction("downloadSample");
              }}
            >
              <span className={styles.icon} />
              <span className={styles.text}>ダウンロード</span>
            </a>
          </div>
        )}
        <div
          className={styles.buttonContainer}
          data-testid="features-Modal-PaidConfirm"
        >
          <Button
            color="navy"
            className={styles.button}
            onClick={() => modal.current && modal.current.close()}
          >
            閉じる
          </Button>
          <Button
            color="orange"
            className={styles.button}
            onClick={() => {
              // B2B_NVS_FR_2018-5730【Atlas】従量課金レポートサンプルDL時のingest追加・修正
              // B、従量課金確認ポップアップ内のダウンロードボタン
              // ②「閲覧する」ボタン（修正）
              // clickイベント
              // ダウンロードのATLAS情報は元のロジックのままであり、ここで均一に処理されるのではなく、コンポーネントの外側の発信者によって送信されます。
              trackAction("clickPayByAmountView");
              props.open();
              modal.current && modal.current.close();
            }}
          >
            閲覧する
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PaidConfirm;
