export const actions = {
  /**
   * ブックマークメニュー
   *
   * @param {?string} eventName イベント名
   * @param {?string} pageName ページ名
   * @param {?string} url ブックマーク遷移を実行したページのURL
   * @param {?string} category1 ページの所属する大カテゴリー名
   * @param {?string} category2 ページの所属する小カテゴリー名
   * @param {?string} category3 ページの所属する？カテゴリー名
   * @param {?string} bookmarkString ブックマーク名
   * @param {number} bookmarkOrder 企業・業界各括り内でのブックマークの並び順
   * @param {number} bookmarkCompanyNum 企業ブックマークの総数
   * @param {number} bookmarkIndustryNum 業界ブックマークの総数
   * @param {?string} place イベントの場所
   * @param {?string} placeDetail イベント詳細
   */
  bookmarkMenu: ({
    eventName = "",
    pageName = "",
    url = "",
    category1 = "",
    category2 = "",
    category3 = "",
    bookmarkString = "",
    bookmarkOrder = 0,
    bookmarkCompanyNum = 0,
    bookmarkIndustryNum = 0,
    place = "",
    placeDetail = ""
  }) => {
    return {
      action: "click",
      category: "link",
      events: eventName,
      obj: {
        custom_vars: {
          product_family: "ValueSearch",
          product: ATLAS_PRODUCT_NAME,
          page_title: "日経ValueSearch",
          page_name: pageName,
          page_num: 1,
          url: url,
          referrer: document.referrer,
          category_l1: category1,
          category_l2: category2,
          category_l3: category3,
          action: {
            location: url
          },
          bookmark_string: bookmarkString,
          order: bookmarkOrder,
          display_company_num: bookmarkCompanyNum,
          display_industry_num: bookmarkIndustryNum,
          place: place,
          place_detail: placeDetail
        }
      }
    };
  },
  /**
   * 指標選択肢
   *
   * @param {?string} type アクションタイプ（open|select)
   * @param {?string} selected 選ばれている選択肢
   * @param {?string} selecting 選ばれた選択肢
   * @param {?string} options 選択肢リスト
   */
  selectBox: ({
    type = "open",
    selected = "",
    selecting = "",
    options = []
  }) => {
    return {
      action: "click",
      category: "selectBox",
      events: `比較表指標_${type}`,
      obj: {
        custom_vars: {
          selected,
          selecting,
          options,
          ingest_version: 1,
          ingest_version_date: "2024-06-30"
        }
      }
    };
  },
  /**
   * 比較表 結果表示ボタン
   */
  openResult: (state?: "to-be-removed") => ({
    action: "click",
    category: "button",
    events: "比較表_結果表示",
    obj: {
      custom_vars: {
        state: state
      }
    }
  }),
  /**
   * 企業リスト作成 結果表示ボタン
   */
  comparisonOpenResult: ({ tabName }: { tabName: string }) => ({
    action: "click",
    category: "button",
    events: "比較表_結果表示",
    obj: {
      custom_vars: {
        tab_id: tabName
      }
    }
  }),

  /**
   * 企業活動情報
   *
   * @param {?string} eventName イベント名
   * @param {number} order 記事の並び順
   * @param {?string} title 記事名
   * @param {?string} displayDate 日付
   * @param {?string} genre 記事の分類
   * @param {?string} place イベントの場所
   * @param {?string} placeDetail イベント詳細
   */
  corporationStrategy: ({
    eventName = "",
    order = 0,
    title = "",
    displayDate = "",
    genre = "",
    place = "",
    placeDetail = ""
  }) => {
    return {
      action: "click",
      category: "link",
      events: eventName,
      obj: {
        custom_vars: {
          product_family: "ValueSearch",
          product: ATLAS_PRODUCT_NAME,
          page_title: "日経ValueSearch",
          page_name: "ホーム",
          page_num: 1,
          url: location.href,
          referrer: document.referrer,
          category_l1: "ホーム",
          category_l2: null,
          category_l3: null,
          action: {
            location: location.href
          },
          order: order,
          title: title,
          display_date: displayDate,
          genre: genre,
          place: place,
          place_detail: placeDetail
        }
      }
    };
  },
  /**
   * Excel アドインテンプレート
   *
   * @param {?string} templateName テンプレート名
   * @param {?string} type ボタン種別（link/button)
   */
  excelAddIn: ({ templateName = "", type = "" }) => {
    return {
      action: "click",
      category: type,
      events: "download-" + templateName,
      obj: {
        custom_vars: {
          product_family: "ValueSearch",
          product: ATLAS_PRODUCT_NAME,
          page_title: "日経ValueSearch",
          page_name: "Excel アドインテンプレート集",
          page_num: 1,
          url: location.href,
          referrer: document.referrer,
          category_l1: "便利ツール",
          category_l2: null,
          category_l3: null,
          action: {
            location: location.href,
            name: "download"
          }
        }
      }
    };
  },
  /**
   * 業界解説レポート(グローバル)
   *
   * @param {?string} eventName イベント名
   * @param {number} colOrder 項目の行内の並び順（何列目か？）
   * @param {number} rowOrder 項目の行の並び順（何行目か？）
   * @param {?string} updateDate 更新日
   * @param {?string} place イベントの場所
   * @param {?string} placeDetail イベント詳細
   */
  globalIndustryReport: ({
    eventName = "",
    colOrder = 0,
    rowOrder = 0,
    updateDate = "",
    place = "",
    placeDetail = ""
  }) => {
    return {
      action: "click",
      category: "link",
      events: eventName,
      obj: {
        custom_vars: {
          product_family: "ValueSearch",
          product: ATLAS_PRODUCT_NAME,
          page_title: "日経ValueSearch",
          page_name: "ホーム",
          page_num: 1,
          url: location.href,
          referrer: document.referrer,
          category_l1: "ホーム",
          category_l2: null,
          category_l3: null,
          action: {
            location: location.href
          },
          column_order: colOrder,
          row_order: rowOrder,
          update_date: updateDate,
          place: place,
          place_detail: placeDetail
        }
      }
    };
  },
  /**
   * グローバルナビゲーション
   *
   * @param {?string} eventName イベント名
   * @param {?string} pageName リンク先ページ名
   * @param {?string} category1 リンク先ページの所属する大カテゴリー名
   * @param {?string} category2 リンク先ページの所属する小カテゴリー名
   * @param {?string} category3
   * @param {?string} place イベントの場所
   * @param {?string} placeDetail イベント詳細
   */
  globalNavigation: ({
    eventName = "",
    pageName = "",
    category1 = "",
    category2 = "",
    category3 = "",
    place = "",
    placeDetail = ""
  }) => {
    return {
      action: "click",
      category: "link",
      events: eventName,
      obj: {
        custom_vars: {
          product_family: "ValueSearch",
          product: ATLAS_PRODUCT_NAME,
          page_title: "日経ValueSearch",
          page_num: 1,
          url: location.href,
          referrer: document.referrer,
          action: {
            location: location.href
          },
          page_name: pageName,
          category_l1: category1,
          category_l2: category2,
          category_l3: category3,
          place: place,
          place_detail: placeDetail
        }
      }
    };
  },
  /**
   * 検索
   *
   * @param {?string} pageName ページ名
   * @param {?string} url 検索を実行したページのURL
   * @param {?string} category1 ページの所属する大カテゴリー名
   * @param {?string} category2 ページの所属する小カテゴリー名
   * @param {?string} category3 ページの所属する？カテゴリー名
   * @param {?string} queryString 検索文字列
   * @param {boolean} queryUseSuggest 検索サジェスト機能利用の有無
   */
  headerSearch: ({
    pageName = "",
    url = "",
    category1 = "",
    category2 = "",
    category3 = "",
    queryString = "",
    queryUseSuggest = false
  }) => {
    return {
      action: "click",
      category: "link",
      events: "main_menu_headline-検索",
      obj: {
        custom_vars: {
          product_family: "ValueSearch",
          product: ATLAS_PRODUCT_NAME,
          page_title: "日経ValueSearch",
          page_name: pageName,
          page_num: 1,
          url: url,
          referrer: document.referrer,
          category_l1: category1,
          category_l2: category2,
          category_l3: category3,
          action: {
            location: url
          },
          query_string: queryString,
          query_use_suggest: queryUseSuggest
        }
      }
    };
  },
  /**
   * 閲覧履歴メニュー
   *
   * @param {?string} eventName イベント名
   * @param {?string} pageName ページ名
   * @param {?string} category1 ページの所属する大カテゴリー名
   * @param {?string} category2 ページの所属する小カテゴリー名
   * @param {?string} category3 ページの所属する？カテゴリー名
   * @param {?string} historyString 閲覧履歴名
   * @param {number} historyOrder 企業・業界各括り内での閲覧履歴の並び順
   * @param {number} historyCompanyNum 企業閲覧履歴の総数
   * @param {number} historyIndustryNum 業界閲覧履歴の総数
   * @param {?string} place イベントの場所
   * @param {?string} placeDetail イベント詳細
   */
  historyMenu: ({
    eventName = "",
    pageName = "",
    category1 = "",
    category2 = "",
    category3 = "",
    historyString = "",
    historyOrder = 0,
    historyCompanyNum = 0,
    historyIndustryNum = 0,
    place = "",
    placeDetail = ""
  }) => {
    return {
      action: "click",
      category: "link",
      events: eventName,
      obj: {
        custom_vars: {
          product_family: "ValueSearch",
          product: ATLAS_PRODUCT_NAME,
          page_title: "日経ValueSearch",
          page_name: pageName,
          page_num: 1,
          url: location.href,
          referrer: document.referrer,
          category_l1: category1,
          category_l2: category2,
          category_l3: category3,
          action: {
            location: location.href
          },
          history_string: historyString,
          order: historyOrder,
          display_company_num: historyCompanyNum,
          display_industry_num: historyIndustryNum,
          place: place,
          place_detail: placeDetail
        }
      }
    };
  },
  /**
   * ホーム画面 ブックマーク
   *
   * @param {?string} eventName イベント名
   * @param {?string} bookmarkString ブックマーク名
   * @param {number} order ブックマークの並び順
   * @param {number} companyNum 企業ブックマークの総数
   * @param {number} industryNum 業界ブックマークの総数
   * @param {?string} place イベントの場所
   * @param {?string} placeDetail イベント詳細
   */
  homeBookmark: ({
    eventName = "",
    bookmarkString = "",
    order = 0,
    companyNum = 0,
    industryNum = 0,
    place = "",
    placeDetail = ""
  }) => {
    return {
      action: "click",
      category: "link",
      events: eventName,
      obj: {
        custom_vars: {
          product_family: "ValueSearch",
          product: ATLAS_PRODUCT_NAME,
          page_title: "日経ValueSearch",
          page_name: "ホーム",
          page_num: 1,
          url: location.href,
          referrer: document.referrer,
          category_l1: "ホーム",
          category_l2: null,
          category_l3: null,
          action: {
            location: location.href
          },
          bookmark_string: bookmarkString,
          order: order,
          display_company_num: companyNum,
          display_industry_num: industryNum,
          place: place,
          place_detail: placeDetail
        }
      }
    };
  },
  /**
   * 日経 Bizトレンド
   *
   * @param {string} eventName イベント名
   * @param {string} type ボタン種別（link/tub)
   * @param {number} order 上から何番目の記事がクリックされたか
   * @param {string?} title
   * @param {string?} displayDate
   * @param {boolean?} newFlag new表記があるか
   * @param {boolean?} imageFlag 画像があるか
   * @param {string?} place イベントの場所
   * @param {string?} placeDetail イベント詳細
   */
  homeHighlight: ({
    eventName = "",
    type = "",
    order = 0,
    title = "",
    displayDate = "",
    newFlag = false,
    imageFlag = false,
    place = "",
    placeDetail = ""
  }) => {
    return {
      action: "click",
      category: type,
      events: eventName,
      obj: {
        custom_vars: {
          product_family: "ValueSearch",
          product: ATLAS_PRODUCT_NAME,
          page_title: "日経ValueSearch",
          page_name: "ホーム",
          page_num: 1,
          url: location.href,
          referrer: document.referrer,
          category_l1: "ホーム",
          category_l2: null,
          category_l3: null,
          action: {
            location: location.href
          },
          order: order,
          title: title,
          display_date: displayDate,
          new_flag: newFlag,
          image_flag: imageFlag,
          place: place,
          place_detail: placeDetail
        }
      }
    };
  },
  /**
   * ホーム画面 履歴
   *
   * @param {?string} eventName イベント名
   * @param {?string} historyString 履歴名
   * @param {number} order 履歴の並び順
   * @param {number} companyNum 企業履歴の総数
   * @param {number} industryNum 業界履歴の総数
   * @param {?string} place イベントの場所
   * @param {?string} placeDetail イベント詳細
   */
  homeHistory: ({
    eventName = "",
    historyString = "",
    order = 0,
    companyNum = 0,
    industryNum = 0,
    place = "",
    placeDetail = ""
  }) => {
    return {
      action: "click",
      category: "link",
      events: eventName,
      obj: {
        custom_vars: {
          product_family: "ValueSearch",
          product: ATLAS_PRODUCT_NAME,
          page_title: "日経ValueSearch",
          page_name: "ホーム",
          page_num: 1,
          url: location.href,
          referrer: document.referrer,
          category_l1: "ホーム",
          category_l2: null,
          category_l3: null,
          action: {
            location: location.href
          },
          history_string: historyString,
          order: order,
          display_company_num: companyNum,
          display_industry_num: industryNum,
          place: place,
          place_detail: placeDetail
        }
      }
    };
  },
  /**
   * ホーム画面
   *
   * @param {?string} eventName イベント名
   * @param {?string} pageName 記事ID
   * @param {?string} order1 記事名
   * @param {?string} order2 リンク先URL
   * @param {?string} pageName リンク先ページ名
   * @param {?string} category1 リンク先カテゴリー
   * @param {?string} category2
   * @param {?string} category3
   * @param {?string} place イベントの場所
   * @param {?string} place_detail イベント詳細
   * @param {?string} menuEvent メニュー開閉イベント
   */
  homeMenu: ({
    eventName = "",
    order1 = "",
    order2 = "",
    pageName = "",
    category1 = "",
    category2 = "",
    category3 = "",
    place = "",
    place_detail = "",
    menuEvent = ""
  }) => {
    return {
      action: "click",
      category: "link",
      events: menuEvent ? menuEvent : eventName,
      obj: {
        custom_vars: {
          product_family: "ValueSearch",
          product: ATLAS_PRODUCT_NAME,
          page_title: "日経ValueSearch",
          page_num: 1,
          url: location.href,
          referrer: document.referrer,
          location: location.href,
          order_l1: order1,
          order_l2: order2,
          page_name: pageName,
          category_l1: category1,
          category_l2: category2,
          category_l3: category3,
          place: menuEvent ? menuEvent : place,
          place_detail: menuEvent ? null : place_detail
        }
      }
    };
  },
  /**
   * Myニュース
   *
   * @param {?string} eventName イベント名
   * @param {?string} categoryName カテゴリー名
   * @param {number} hit ニュースの全件数
   * @param {number} pageNum 表示中のページ番号
   * @param {number} order ニュースの並び順
   * @param {?string} title ニュースの見出し
   * @param {?string} displayDate ニュースの日付
   * @param {?string} genre ニュースの分類
   * @param {?string} place イベントの場所
   * @param {?string} placeDetail イベント詳細
   */
  homeMyNews: ({
    eventName = "",
    categoryName = "",
    hit = 0,
    pageNum = 1,
    order = 0,
    title = "",
    displayDate = "",
    genre = "",
    place = "",
    placeDetail = ""
  }) => {
    return {
      action: "click",
      category: categoryName,
      events: eventName,
      obj: {
        custom_vars: {
          product_family: "ValueSearch",
          product: ATLAS_PRODUCT_NAME,
          page_title: "日経ValueSearch",
          page_name: "ホーム",
          page_num: pageNum,
          url: location.href,
          referrer: document.referrer,
          category_l1: "ホーム",
          category_l2: null,
          category_l3: null,
          action: {
            location: location.href
          },
          hit: hit,
          order: order,
          title: title,
          display_date: displayDate,
          genre: genre,
          place: place,
          place_detail: placeDetail
        }
      }
    };
  },
  /**
   * ホーム画面 Toolbox（Myニュース・ブックマーク・履歴）タブ
   *
   * @param {?string} eventName イベント名
   * @param {?string} place イベントの場所
   */
  homeToolboxTab: ({ eventName = "", place = "" }) => {
    return {
      action: "click",
      category: "tab",
      events: eventName,
      obj: {
        custom_vars: {
          product_family: "ValueSearch",
          product: ATLAS_PRODUCT_NAME,
          page_title: "日経ValueSearch",
          page_name: "ホーム",
          page_num: 1,
          url: location.href,
          referrer: document.referrer,
          category_l1: "ホーム",
          category_l2: null,
          category_l3: null,
          action: {
            location: location.href
          },
          place: place,
          place_detail: "見出し"
        }
      }
    };
  },
  /**
   * 業界ページ全体
   *
   * @param {string} industryHierarchy 業界階層
   * @param {string} industryCode 業界コード
   * @param {string} type ボタン種別（link/button)
   * @param {string} eventName イベント名
   * @param {string} destination リンク先URL
   */
  industry: ({
    industryHierarchy = "",
    industryCode = "",
    type = "",
    eventName = "",
    destination = ""
  }) => {
    return {
      action: "click",
      category: type,
      events: eventName,
      obj: {
        custom_vars: {
          product_family: "ValueSearch",
          product: ATLAS_PRODUCT_NAME,
          page_title: "日経ValueSearch",
          page_name: "業界概要",
          page_num: 1,
          url: location.href,
          referrer: document.referrer,
          category_l1: "業界",
          category_l2: "業界情報",
          category_l3: "業界概要",
          content_id: industryCode,
          content_name: industryHierarchy,
          action: {
            location: location.href,
            destination
          },
          industry: industryHierarchy
        }
      }
    };
  },
  /**
   * ホーム画面 日経業界分析レポート
   *
   * @param {string} industryName 業界名
   * @param {string} destination リンク先URL / レポートID
   * @param {string} update 更新日
   * @param {string} industryHierarchy 業界階層
   */
  homeIndustryReport: ({
    destination = "",
    update = "",
    industryHierarchy = ""
  }) => {
    return {
      action: "click",
      category: "button",
      events: "詳細レポートダウンロード（日経業界分析レポート）",
      obj: {
        custom_vars: {
          action: {
            location: location.href,
            destination
          },
          update_date: update,
          industry: industryHierarchy,
          ingest_version: 1,
          ingest_version_date: "2025-02-05"
        }
      }
    };
  },
  /**
   * 日経業界分析レポート一覧
   *
   * @param {string} industryName 業界名
   * @param {string} type ボタン種別（link/button)
   * @param {?string} pageNum ページ数
   * @param {string} destination リンク先URL / レポートID
   * @param {string} order 順番
   * @param {string} update 更新日
   * @param {string} industryHierarchy 業界階層
   */
  industryReportList: ({
    industryName = "",
    type = "",
    pageNum = "1",
    destination = "",
    order = "",
    update = "",
    industryHierarchy = ""
  }) => {
    const eventPrefix =
      type === "link" ? "業界概要リンク-" : "download-日経Biz業界レポート作成-";
    return {
      action: "click",
      category: type,
      events: eventPrefix + industryName,
      obj: {
        custom_vars: {
          product_family: "ValueSearch",
          product: ATLAS_PRODUCT_NAME,
          page_title: "日経ValueSearch",
          page_name: "日経Biz 業界レポート一覧",
          page_num: pageNum,
          url: location.href,
          referrer: document.referrer,
          category_l1: "ホーム",
          category_l2: "日経Biz 業界レポート一覧",
          category_l3: null,
          action: {
            location: location.href,
            destination
          },
          order,
          update_date: update,
          industry: industryHierarchy
        }
      }
    };
  },
  /**
   * 業界小分類ページ 詳細レポート
   * @param {string} industryName 業界名
   * @param {string} destination リンク先URL
   * @param {string} industryHierarchy 業界階層
   */
  industryReportSmallClassification: ({
    industryName = "",
    destination = "",
    industryHierarchy = ""
  }) => {
    return {
      action: "click",
      category: "button",
      events: "download-日経Biz業界レポート作成-" + industryName,
      obj: {
        custom_vars: {
          product_family: "ValueSearch",
          product: ATLAS_PRODUCT_NAME,
          page_title: "日経ValueSearch",
          page_name: "日経Biz 業界レポート一覧",
          url: location.href,
          referrer: document.referrer,
          category_l1: "業界小分類ページ",
          category_l2: "業界分析レポート",
          category_l3: null,
          action: {
            location: location.href,
            destination
          },
          industry: industryHierarchy
        }
      }
    };
  },

  /**
   * ログイン画面
   *
   * @param {?string} target クリック対象
   * @param {?string} destination 遷移先URL
   */
  login: ({
    target = "日経テレコン",
    destination = ""
  }: {
    target?: string;
    destination: string;
  }) => {
    return {
      action: "open",
      category: "outboundLink",
      events: `login_${target}`,
      obj: {
        custom_vars: {
          page_name: target,
          category_l1: target,
          category_l2: "",
          category_l3: "",
          destination,
          ingest_version: 1,
          ingest_version_date: "2024-06-30"
        }
      }
    };
  },
  /**
   * 一括検索 企業タブ 検索結果の企業比較・ブックマーク追加
   *
   * @param {?string} eventName イベント名
   * @param {string[]} checkCompany チェックボックスの状態を変更した行の企業名
   */
  searchCorpResultButton: ({ eventName = "", checkCompany = [] }) => {
    return {
      action: "click",
      category: "button",
      events: eventName,
      obj: {
        custom_vars: {
          action: {
            location: location.href
          },
          check_company: checkCompany
        }
      }
    };
  },
  /**
   * 一括検索 企業タブ 検索結果項目のチェックボックス
   *
   * @param {string} variant チェックボックスの状態（on|off）
   * @param {?string} updateCompany チェックボックスの状態を変更した行の企業名
   */
  searchCorpResultItemCheckbox: ({ variant = "", updateCompany = "" }) => {
    return {
      action: "click",
      category: "button",
      events: "searchlist_corp_checkbox",
      obj: {
        custom_vars: {
          action: {
            location: location.href
          },
          variant: variant,
          update_company: updateCompany
        }
      }
    };
  },
  /**
   * 一括検索 インサイトタブ パックドバブルチャート
   *
   * @param {?string} eventName イベント名
   * @param {?string} pageName ページ名
   * @param {string} selectedBubbleWord クリックされたバブルのワード
   * @param {?string[]} searchKeywordTags 検索文字列
   * @param {number} queryHit 検索ヒット数
   * @param {Array} bubbleInfo バブルチャート構成
   */
  searchInsightPackedBubbleChart: ({
    eventName = "",
    selectedBubbleWord = "",
    bubble = []
  }) => {
    return {
      action: "click",
      category: "link",
      events: eventName,
      obj: {
        custom_vars: {
          action: {
            location: "https://valuesearch.nikkei.co.jp/searchlist/summary"
          },
          query_string: selectedBubbleWord,
          bubble: bubble
        }
      }
    };
  },
  /**
   * 一括検索 インサイトタブ ツリーマップチャート
   *
   * @param {?string} eventName イベント名
   * @param {?string} queryString 検索文字列
   * @param {Array} tree チャート構成
   */
  searchInsightTreeMapChart: ({
    eventName = "",
    queryString = "",
    tree = []
  }) => {
    return {
      action: "click",
      category: "link",
      events: eventName,
      obj: {
        custom_vars: {
          action: {
            location: location.href
          },
          query_string: queryString,
          tree: tree
        }
      }
    };
  },
  /**
   * 一括検索 コレクションタブ 絞り込み条件変更
   *
   * @param {string} items 選択項目
   * @param {string} operation 操作内容
   */
  searchCollectionConditions: ({
    items,
    operation
  }: {
    items: "対象コレクション" | "ソート順" | "表示件数";
    operation: "ドロップダウンを開く" | "条件を変更";
  }) => {
    return {
      action: "click",
      category: "selectBox",
      page_title: "日経バリューサーチ",
      page_name: "コレクション",
      category_l1: "一括検索",
      category_l2: "コレクション",
      events: `コレクション検索/${items}/${operation}`
    };
  },
  /**
   * 一括検索 自由入力での検索キーワード設定時
   *
   * @param {?string} keyword 入力した検索ワード
   */
  searchKeyword: ({ keyword = "" }) => {
    return {
      action: "click",
      category: "link",
      events: `searchlist_headline-検索-フリーワード`,
      obj: {
        custom_vars: {
          action: {
            location: location.href
          },
          query_string: keyword
        }
      }
    };
  },
  /**
   * 日経 Bizトレンド
   *
   * @param {string} eventName イベント名
   * @param {string} type ボタン種別（link/button)
   * @param {string} id 記事ID
   * @param {string} name 記事名
   * @param {string} destination リンク先URL
   * @param {?string} pageNum ページ数
   * @param {?string} industryCode 業界コード
   * @param {?string} companyCode 企業コード
   */
  trendReport: ({
    eventName = "",
    type = "",
    id = "",
    name = "",
    destination = "",
    pageNum = "1",
    industryCode = "",
    companyCode = ""
  }) => {
    return {
      action: "click",
      category: type,
      events: eventName,
      obj: {
        custom_vars: {
          product_family: "ValueSearch",
          product: ATLAS_PRODUCT_NAME,
          page_title: "日経ValueSearch",
          page_name: "日経 Bizトレンド",
          page_num: pageNum,
          url: location.href,
          referrer: document.referrer,
          category_l1: "ポップアップ",
          category_l2: "日経 Bizトレンド",
          category_l3: null,
          content_id: id,
          content_name: name,
          action: {
            location: location.href,
            destination
          },
          industry_code: industryCode,
          company_code: companyCode
        }
      }
    };
  },
  /**
   * 一括検索 キーワード
   *
   * @param {Array} andKeywords And条件のキーワード配列
   * @param {Array} orKeywords Or条件のキーワード配列
   * @param {Array} notKeywords Not条件のキーワード配列
   */
  globalSearchKeyword: ({
    andKeywords = Array<string>(),
    orKeywords = Array<string>(),
    notKeywords = Array<string>()
  }) => {
    return {
      action: "submit",
      category: "input",
      events: "一括検索/検索キーワード",
      obj: {
        custom_vars: {
          andKeywords,
          orKeywords,
          notKeywords
        }
      }
    };
  },
  /**
   * 高度な検索 入力フォームを開く
   */
  openAdvancedSearch: () => {
    return {
      action: "click",
      category: "button",
      events: "高度な検索/入力フォームを開く",
      obj: {}
    };
  },
  /**
   * 一括検索 ワードタグ押下
   * @param {string} word タグのワード
   * @param {string[]} tags 表示されている全タグ
   */
  searchWithWordTag: ({ word, tags }: { word: string; tags: string[] }) => {
    return {
      action: "click",
      category: "tag",
      events: "一括検索/ワードタグ押下",
      obj: {
        custom_vars: {
          word,
          tags
        }
      }
    };
  },
  /**
   * コレクションに保存（新規 or 追加）
   *
   * @param {string} action イベント名
   * @param {string} corpCount イベント名
   */
  saveToBinder: ({
    action,
    corpCount
  }: {
    action: "新規コレクション" | "登録済みコレクション";
    corpCount: number;
  }) => {
    return {
      action: "click",
      category: "button",
      events: `企業検索/コレクションへ保存/${action}`,
      obj: {
        custom_vars: {
          corpCount
        }
      }
    };
  },
  /**
   * コレクションへ保存 バルーンを開く
   */
  openBinderBalloon: () => {
    return {
      action: "click",
      category: "button",
      events: "企業検索/コレクションへ保存/バルーンを開く"
    };
  },
  /**
   * コレクションへ保存 新規作成フォームを開く
   */
  onOpenCreateNewForm: () => {
    return {
      action: "click",
      category: "button",
      events: "企業検索/コレクションへ保存/新規作成フォームを開く"
    };
  },
  /**
   * コレクションコピー マイコレクションにコピーする
   * @param {string} id コレクションID
   * @param {string} name コレクション名
   * @param {string} type イベントタイプ
   */
  onCopyToMyCollection: ({
    id,
    name,
    type
  }: {
    id: string;
    name: string;
    type: "一覧" | "詳細";
  }) => {
    return {
      action: "click",
      category: "button",
      events: `コレクション/日経コレクション/${type}/マイコレクションにコピー`,
      obj: {
        custom_vars: { id, name }
      }
    };
  },
  /**
   * マイコレクションピン留め ボタン押下時
   * @param {string} id コレクションID
   * @param {string} name コレクション名
   * @param {string} action 行った操作
   * @param {string} events イベント名
   */
  onClickMyCollectionPinned: ({
    id,
    name,
    action,
    events
  }: {
    id: string;
    name: string;
    action: "ピン留め" | "ピン留め解除";
    events: string;
  }) => ({
    action: "click",
    category: "button",
    events,
    obj: {
      custom_vars: {
        id,
        name,
        action,
        ingest_version: 1,
        ingest_version_date: "2024-11-26"
      }
    }
  }),
  /**
   * マイコレクション操作 操作ボタン押下時
   * @param {string} action アクション名
   * @param {string} location 操作ボタンの位置
   * @param {?string} type 操作ページタイプ
   */
  onClickMyCollectionOperation: ({
    action,
    location,
    type
  }: {
    action: "編集" | "共有" | "複製" | "削除" | "公開";
    location: "トップ" | "コレクション";
    type?: "一覧" | "詳細";
  }) => ({
    action: "click",
    category: "button",
    events: `${location}/マイコレクション${(type && `/${type}`) || ""}/操作`,
    obj: {
      custom_vars: { action }
    }
  }),
  /**
   * マイコレクション操作 操作実行
   * @param {string} action アクション名
   * @param {string} id コレクションID
   * @param {string} name コレクション名
   */
  doneMyCollectionOperation: ({
    action,
    id,
    name
  }: {
    action: "編集" | "共有" | "複製" | "削除" | "公開" | "補足情報編集";
    id: string;
    name: string;
  }) => ({
    action: "click",
    category: "button",
    events: "コレクション/操作/実行",
    obj: {
      custom_vars: { action, id, name }
    }
  }),
  /**
   * マイコレクション
   * @param {string} sort 表示順
   * @param {string} collectionType コレクション種別
   */
  onChangeMyCollectionSort: ({
    sort,
    collectionType
  }: {
    sort: "更新順" | "登録順" | "関連度順";
    collectionType: "マイコレクション" | "日経コレクション";
  }) => ({
    action: "click",
    category: "button",
    events: "コレクション/一覧/表示順",
    obj: {
      custom_vars: {
        sort,
        collection_type: collectionType
      }
    }
  }),
  /**
   * 企業検索 絞り込み条件 ドロップダウンを開く
   * @param {string} target 対象
   */
  openCorpSearchDropDown: ({
    target
  }: {
    target: "検索対象データ" | "企業種別" | "ソート順" | "表示件数";
  }) => ({
    action: "click",
    category: "selectBox",
    events: `企業検索/${target}/ドロップダウンを開く`
  }),
  /**
   * 企業検索 絞り込み条件 条件を変更
   * @param {string} target 対象
   */
  changeCorpSearchFilter: ({
    target
  }: {
    target: "検索対象データ" | "企業種別" | "ソート順" | "表示件数";
  }) => ({
    action: "click",
    category: "selectBox",
    events: `企業検索/${target}/条件を変更`
  }),
  /**
   * 媒体選択 検索
   * @param {string} keyword キーワード
   * @param {string} period 期間
   * @param {string[]} target 検索範囲
   * @param {string[]} media 媒体
   */
  searchReport: ({
    keyword,
    orKeyword,
    notKeyword,
    period,
    target,
    media
  }: {
    keyword: string;
    orKeyword: string;
    notKeyword: string;
    period: string;
    target: string[];
    media: string[];
  }) => ({
    action: "click",
    category: "button",
    events: "レポート・雑誌/検索",
    obj: {
      custom_vars: {
        keyword,
        orKeyword,
        notKeyword,
        period,
        target,
        media
      }
    }
  }),
  /**
   * 媒体選択 一覧から選択
   * @param {string} media メディア
   * @param {string} category ビール
   */
  selectCategoryFromReportIndex: ({
    media,
    category
  }: {
    media: string;
    category: string;
  }) => ({
    action: "click",
    category: "link",
    events: "レポート・雑誌/一覧から選ぶ",
    obj: {
      custom_vars: {
        media,
        category
      }
    }
  }),
  /**
   * AI解析 ${sectionName} 記事リンクを開く
   *
   * @param {string} sectionName 画面セクションの名称
   * @param {string} url リンクのURL
   */
  openInsightArticleLink: ({
    sectionName,
    url = ""
  }: {
    sectionName: "取り巻く環境" | "取り巻く企業・業界マップ";
    url: string;
  }) => {
    return {
      action: "click",
      category: "link",
      events: `AI解析/${sectionName}/記事リンクを開く`,
      obj: {
        custom_vars: {
          url
        }
      }
    };
  },
  /**
   * ヘッダー クイックメニューを表示
   */
  openHeaderQuickMenu: () => {
    return {
      action: "click",
      category: "button",
      events: "ヘッダー/クイックメニュー"
    };
  },
  /**
   * ヘッダー クイックメニューリンクを表示
   *
   * @param {string} name メニュー名
   * @param {string} url 遷移先URL
   */
  openHeaderQuickMenuLink: ({ name, url }: { name: string; url: string }) => {
    return {
      action: "click",
      category: "link",
      events: "ヘッダー/クイックメニュー/メニュー",
      obj: {
        custom_vars: {
          name,
          url
        }
      }
    };
  },
  /**
   * ヘッダー コレクションボタンを押下
   */
  clickHeaderBinderButton: () => {
    return {
      action: "click",
      category: "button",
      events: "ヘッダー/コレクション"
    };
  },
  /**
   * ヘッダー コレクション コレクション一覧ボタンを押下
   */
  clickHeaderBinderListButton: ({ name }: { name: string }) => {
    return {
      action: "click",
      category: "button",
      events: "ヘッダー/コレクション/コレクション一覧",
      obj: {
        custom_vars: {
          name
        }
      }
    };
  },
  /**
   * ヘッダー コレクション 企業ボタンを押下
   *
   * @param {string} name 企業名
   */
  clickHeaderBinderItemCorpButton: ({ name }: { name: string }) => {
    return {
      action: "click",
      category: "button",
      events: "ヘッダー/コレクション/企業",
      obj: {
        custom_vars: {
          name
        }
      }
    };
  },
  /**
   * ヘッダー コレクション 業界ボタンを押下
   *
   * @param {string} name 業界名
   */
  clickHeaderBinderItemIndustryButton: ({ name }: { name: string }) => {
    return {
      action: "click",
      category: "button",
      events: "ヘッダー/コレクション/業界",
      obj: {
        custom_vars: {
          name
        }
      }
    };
  },
  /**
   * ヘッダー コレクション リストをスクロール
   *
   * @param {string} list スクロールしたリスト名
   */
  scrollBinderList: ({ list }: { list: string }) => {
    return {
      action: "scroll",
      category: "list",
      events: "ヘッダー/コレクション",
      obj: {
        custom_vars: {
          list
        }
      }
    };
  },

  /**
   * スクリーニング　次へボタン選択時
   *
   * @param {string} scenario_group_id  選択したシナリオグループID
   * @param {string} scenario_group_name 選択したシナリオグループ名
   * @param {string} scenario_id 選択したシナリオを特定可能なID
   * @param {string} scenario_name 選択したシナリオ名
   */
  selectScenario: ({
    scenario_group_id,
    scenario_group_name,
    scenario_id,
    scenario_name
  }: {
    scenario_group_id: string;
    scenario_group_name: string;
    scenario_id: string;
    scenario_name: string;
  }) => {
    return {
      action: "click",
      category: "button",
      events: "スクリーニング(企業)/シナリオから選ぶ/シナリオ選択",
      obj: {
        custom_vars: {
          scenario_group_id,
          scenario_group_name,
          scenario_id,
          scenario_name
        }
      }
    };
  },
  /**
   * スクリーニング　登録した条件から選択時
   */
  selectScenarioCondition: (state?: "to-be-removed") => {
    return {
      action: "click",
      category: "dropDown",
      events: " スクリーニング(企業)/シナリオから選ぶ/登録した条件から選択",
      obj: {
        custom_vars: {
          state: state
        }
      }
    };
  },
  /**
   * スクリーニング　シナリオ再選択時
   */
  scenarioReselect: () => {
    return {
      action: "click",
      category: "button",
      events: "スクリーニング企業/シナリオから選ぶ/シナリオ再選択"
    };
  },
  /**
   * スクリーニング　シナリオ条件クリア
   */
  resetScenarioCondition: () => {
    return {
      action: "click",
      category: "button",
      events: "スクリーニング企業/シナリオから選ぶ/条件クリア"
    };
  },
  /**
   * スクリーニング　	企業種別変更
   */
  changeSourceSelector: () => {
    return {
      action: "click",
      category: "checkBox",
      events: "スクリーニング企業/シナリオから選ぶ/企業種別変更",
      obj: {
        custom_vars: {
          ingest_version: 1,
          ingest_version_date: "2024-06-30"
        }
      }
    };
  },
  /**
   * スクリーニング　絞込条件保存
   */
  saveScenarioCondition: () => {
    return {
      action: "click",
      category: "button",
      events: "スクリーニング企業/シナリオから選ぶ/条件を保存"
    };
  },
  /**
   * スクリーニング　結果表示
   */
  openScreeningResult: () => {
    return {
      action: "click",
      category: "button",
      events: "スクリーニング企業/シナリオから選ぶ/結果表示"
    };
  },
  /**
   * スクリーニング　絞込条件削除
   *
   * @param {string} condition_id  変更があった絞り込み条件を特定可能な ID
   * @param {string} condition_name 変更があった絞り込み条件の項目名
   *
   */
  deleteScreeningCondition: ({
    condition_id,
    condition_name
  }: {
    condition_id: string;
    condition_name: string;
  }) => {
    return {
      action: "click",
      category: "button",
      events: " スクリーニング企業/シナリオから選ぶ/絞込条件削除",
      obj: {
        custom_vars: {
          condition_id,
          condition_name
        }
      }
    };
  },
  /**
   * スクリーニング　	絞込条件変更時(入力項目)
   *
   * @param {string} condition_id  変更があった絞り込み条件を特定可能な ID
   * @param {string} condition_name 変更があった絞り込み条件の項目名
   */
  changeScreeningConditionInput: ({
    condition_id,
    condition_name
  }: {
    condition_id: string;
    condition_name: string;
  }) => {
    return {
      action: "input",
      category: "input",
      events: "スクリーニング企業/シナリオから選ぶ/絞込条件変更",
      obj: {
        custom_vars: {
          condition_id,
          condition_name
        }
      }
    };
  },
  /**
   * スクリーニング　	絞込条件変更時(チェックボックス)
   *
   * @param {string} condition_id  変更があった絞り込み条件を特定可能な ID
   * @param {string} condition_name 変更があった絞り込み条件の項目名
   */
  changeScreeningConditionCheckbox: ({
    condition_id,
    condition_name
  }: {
    condition_id: string;
    condition_name: string;
  }) => {
    return {
      action: "click",
      category: "checkBox",
      events: "スクリーニング企業/シナリオから選ぶ/絞込条件変更",
      obj: {
        custom_vars: {
          condition_id,
          condition_name,
          ingest_version: 1,
          ingest_version_date: "2024-06-30"
        }
      }
    };
  },

  /**
   * スクリーニング　	絞込条件変更時(ドロップダウン)
   *
   * @param {string} condition_id  変更があった絞り込み条件を特定可能な ID
   * @param {string} condition_name 変更があった絞り込み条件の項目名
   */
  changeScreeningConditionDropDown: ({
    condition_id,
    condition_name
  }: {
    condition_id: string;
    condition_name: string;
  }) => {
    return {
      action: "click",
      category: "dropDown",
      events: "スクリーニング企業/シナリオから選ぶ/絞込条件変更",
      obj: {
        custom_vars: {
          condition_id,
          condition_name
        }
      }
    };
  },
  /**
   * スクリーニング　	絞込条件変更時(モーダル)
   *
   * @param {string} condition_id  変更があった絞り込み条件を特定可能な ID
   * @param {string} condition_name 変更があった絞り込み条件の項目名
   */
  changeScreeningConditionModal: ({
    condition_id,
    condition_name
  }: {
    condition_id: string;
    condition_name: string;
  }) => {
    return {
      action: "click",
      category: "modal",
      events: "スクリーニング企業/シナリオから選ぶ/絞込条件変更",
      obj: {
        custom_vars: {
          condition_id,
          condition_name
        }
      }
    };
  },
  /**
   * スクリーニング　	シナリオ条件の変更(ラジオボタン)
   *
   * @param {string} scenario_group_id  選択したシナリオグループID
   * @param {string} scenario_group_name 選択したシナリオグループ名
   * @param {string} scenario_id 選択したシナリオを特定可能なID
   * @param {string} scenario_name 選択したシナリオ名
   */
  changeScenarioConditionRadio: ({
    scenario_group_id,
    scenario_group_name,
    scenario_id,
    scenario_name
  }: {
    scenario_group_id: string;
    scenario_group_name: string;
    scenario_id: string;
    scenario_name: string;
  }) => {
    return {
      action: "select",
      category: "radioButton",
      events: "スクリーニング(企業)/シナリオから選ぶ/シナリオ条件変更",
      obj: {
        custom_vars: {
          scenario_group_id,
          scenario_group_name,
          scenario_id,
          scenario_name
        }
      }
    };
  },
  /**
   * スクリーニング　	シナリオ条件の変更(文字入力)
   *
   * @param {string} scenario_group_id  選択したシナリオグループID
   * @param {string} scenario_group_name 選択したシナリオグループ名
   * @param {string} scenario_id 選択したシナリオを特定可能なID
   * @param {string} scenario_name 選択したシナリオ名
   *
   */
  changeScenarioConditionInput: ({
    scenario_group_id,
    scenario_group_name,
    scenario_id,
    scenario_name
  }: {
    scenario_group_id: string;
    scenario_group_name: string;
    scenario_id: string;
    scenario_name: string;
  }) => {
    return {
      action: "input",
      category: "input",
      events: "スクリーニング(企業)/シナリオから選ぶ/シナリオ条件変更",
      obj: {
        custom_vars: {
          scenario_group_id,
          scenario_group_name,
          scenario_id,
          scenario_name
        }
      }
    };
  },
  /**
   * スクリーニング(企業) 表示項目選択
   * @param {string} type 項目
   */
  selectScreeningCheckbox: ({ type }: { type: string }) => {
    return {
      action: "click",
      category: "dropDown",
      events: `スクリーニング(企業)/項目から探す/${type}`,
      obj: {
        custom_vars: {
          state: "to-be-removed"
        }
      }
    };
  },
  /**
   * スクリーニング(企業) 企業種別選択
   */
  selectScreeningEnterpriseList: () => {
    return {
      action: "click",
      category: "checkBox",
      events: "スクリーニング(企業)/項目から探す/企業種別変更",
      obj: {
        custom_vars: {
          state: "to-be-removed",
          ingest_version: 1,
          ingest_version_date: "2024-06-30"
        }
      }
    };
  },
  /**
   * スクリーニング(企業) シナリオ条件の選択
   */
  selectScreeningScenario: () => {
    return {
      action: "click",
      category: "dropDown",
      events: "スクリーニング(企業)/項目から探す/登録した条件から選択"
    };
  },
  /**
   * スクリーニング(企業) 項目選択
   * @param {string} condition_id 追加された項目を特定可能な ID (あれば)
   * @param {string} condition_name 追加された項目の名前
   */
  selectScreeningList: ({
    condition_id,
    condition_name
  }: {
    condition_id: string;
    condition_name: string;
  }) => {
    return {
      action: "click",
      category: "item",
      events: "スクリーニング(企業)/項目から探す/項目追加",
      obj: {
        custom_vars: {
          condition_id,
          condition_name,
          state: "to-be-removed"
        }
      }
    };
  },
  /**
   * スクリーニング(企業) 項目削除
   * @param {string} condition_id 追加された項目を特定可能なID(あれば)
   * @param {string} condition_name 削除された項目の名前
   */
  deleteScreeningItem: ({
    condition_id,
    condition_name
  }: {
    condition_id: string;
    condition_name: string;
  }) => {
    return {
      action: "click",
      category: "button",
      events: "スクリーニング(企業)/項目から探す/項目削除",
      obj: {
        custom_vars: {
          condition_id,
          condition_name,
          state: "to-be-removed"
        }
      }
    };
  },
  /**
   * スクリーニング(企業)　	項目条件変更時(入力項目)
   *
   * @param {string} condition_id  変更があった絞り込み条件を特定可能な ID
   * @param {string} condition_name 変更があった絞り込み条件の項目名
   */
  changeScreeningItemInput: ({
    condition_id,
    condition_name
  }: {
    condition_id: string;
    condition_name: string;
  }) => {
    return {
      action: "input",
      category: "input",
      events: "スクリーニング(企業)/項目から選ぶ/項目条件変更",
      obj: {
        custom_vars: {
          condition_id,
          condition_name,
          state: "to-be-removed"
        }
      }
    };
  },
  /**
   * スクリーニング(企業)　	項目条件変更時(チェックボックス)
   *
   * @param {string} condition_id  変更があった絞り込み条件を特定可能な ID
   * @param {string} condition_name 変更があった絞り込み条件の項目名
   */
  changeScreeningItemCheckbox: ({
    condition_id,
    condition_name
  }: {
    condition_id: string;
    condition_name: string;
  }) => {
    return {
      action: "click",
      category: "checkBox",
      events: "スクリーニング(企業)/項目から選ぶ/項目条件変更",
      obj: {
        custom_vars: {
          condition_id,
          condition_name,
          state: "to-be-removed",
          ingest_version: 1,
          ingest_version_date: "2024-06-30"
        }
      }
    };
  },

  /**
   * スクリーニング(企業)　	項目条件変更時(ドロップダウン)
   *
   * @param {string} condition_id  変更があった絞り込み条件を特定可能な ID
   * @param {string} condition_name 変更があった絞り込み条件の項目名
   */
  changeScreeningItemDropDown: ({
    condition_id,
    condition_name
  }: {
    condition_id: string;
    condition_name: string;
  }) => {
    return {
      action: "click",
      category: "dropDown",
      events: "スクリーニング(企業)/項目から選ぶ/項目条件変更",
      obj: {
        custom_vars: {
          condition_id,
          condition_name,
          state: "to-be-removed"
        }
      }
    };
  },
  /**
   * スクリーニング(企業)　	項目条件変更時(モーダル)
   *
   * @param {string} condition_id  変更があった絞り込み条件を特定可能な ID
   * @param {string} condition_name 変更があった絞り込み条件の項目名
   */
  changeScreeningItemModal: ({
    condition_id,
    condition_name
  }: {
    condition_id: string;
    condition_name: string;
  }) => {
    return {
      action: "click",
      category: "modal",
      events: "スクリーニング(企業)/項目から選ぶ/項目条件変更",
      obj: {
        custom_vars: {
          condition_id,
          condition_name,
          state: "to-be-removed"
        }
      }
    };
  },
  /**
   * スクリーニング(企業) 項目条件保存
   */
  saveScreeningCondition: () => {
    return {
      action: "click",
      category: "dropDown",
      events: "スクリーニング(企業)/項目から探す/条件を保存",
      obj: {
        custom_vars: {
          state: "to-be-removed",
          ingest_version: 1,
          ingest_version_date: "2024-06-30"
        }
      }
    };
  },
  /**
   * スクリーニング(企業) 結果表示
   */
  resultScreeningCondition: () => {
    return {
      action: "click",
      category: "button",
      events: "スクリーニング(企業)/項目から探す/結果表示",
      obj: {
        custom_vars: {
          state: "to-be-removed"
        }
      }
    };
  },
  /**
   *
   * スクリーニング(企業) 結果表示 表示項目変更
   *
   * @param {string} item 項目名
   */
  selectScreeningDisplayItem: ({ item }: { item: string }) => {
    return {
      action: "click",
      category: "dropDown",
      events: `スクリーニング(企業)/結果比較表/${item}`
    };
  },
  /**
   *
   * スクリーニング(企業) 結果表示 ソート順変更
   *
   * @param {string} order 昇順・降順
   * @param {string} name カラム名
   */
  sortScreeningItem: ({ order, name }: { order: string; name: string }) => {
    return {
      action: "click",
      category: "checkBox",
      events: `スクリーニング(企業)/結果比較表/ソート順変更`,
      obj: {
        custom_vars: {
          order,
          name,
          ingest_version: 1,
          ingest_version_date: "2024-06-30"
        }
      }
    };
  },
  /**
   * スクリーニング(企業) 結果表示 散布図表示ボタン押下
   *
   * @param {number} num_items 散布図の表示企業数
   */
  clickDisplayScreeningScatterPlot: ({ numItems }: { numItems: number }) => {
    return {
      action: "click",
      category: "button",
      events: "スクリーニング(企業)/結果比較表/散布図表示",
      obj: {
        custom_vars: {
          num_items: numItems
        }
      }
    };
  },
  /**
   * スクリーニング(企業) 結果表示 企業種別選択
   */
  changeCompanyScreeningSourceSelector: () => {
    return {
      action: "click",
      category: "checkBox",
      events: "スクリーニング(企業)/結果比較表/企業種別変更",
      obj: {
        custom_vars: {
          ingest_version: 1,
          ingest_version_date: "2024-06-30"
        }
      }
    };
  },
  /**
   * スクリーニング(企業) 結果表示 コレクション追加
   *
   * @param {number} num_items 選択された企業の件数
   */
  clickCompanyScreeningAddCollectionButton: ({
    numItems
  }: {
    numItems: number;
  }) => {
    return {
      action: "click",
      category: "button",
      events: "スクリーニング(企業)/結果比較表/コレクション追加",
      obj: {
        custom_vars: {
          num_items: numItems
        }
      }
    };
  },
  /**
   * AI解析 キーワードバブルクリック
   *
   * @param {string} context コンテキスト
   * @param {string} keyword キーワード
   */
  clickAIKeyword: ({
    context,
    keyword
  }: {
    context: string;
    keyword: string;
  }) => {
    return {
      action: "click",
      category: "button",
      events: `AI解析/インサイトワード/${context}`,
      obj: {
        custom_vars: {
          keyword: keyword
        }
      }
    };
  },
  /**
   * AI解析 関連記事のリンククリック
   *
   * @param {string} articleId ID
   * @param {string} articleTitle タイトル
   */
  clickArticlePopup: ({
    articleId,
    articleTitle
  }: {
    articleId: string;
    articleTitle: string;
  }) => {
    return {
      action: "click",
      category: "link",
      events: "AI解析/インサイトワード/関連記事/記事リンク",
      obj: {
        custom_vars: {
          article_id: articleId,
          article_title: articleTitle
        }
      }
    };
  },
  /**
   * AI解析 関連記事をコレクションに保存
   *
   * @param {string} articleId ID
   * @param {string} articleTitle タイトル
   * @param {string} binderId コレクションID
   * @param {string} binderName コレクション名
   */
  saveBookmarkFromAI: ({
    articleId,
    articleTitle,
    binderId,
    binderName
  }: {
    articleId: string;
    articleTitle: string;
    binderId: string;
    binderName: string;
  }) => {
    return {
      action: "click",
      category: "button",
      events: "AI解析/インサイトワード/関連記事/コレクション保存",
      obj: {
        custom_vars: {
          article_id: articleId,
          article_title: articleTitle,
          binder_id: binderId,
          binder_name: binderName
        }
      }
    };
  },
  /**
   * AI解析 このページのコンテンツについて
   */
  clickAboutPageContents: () => {
    return {
      action: "click",
      category: "button",
      events: "AI解析/インサイトワード/コンテンツについて"
    };
  },
  /**
   * AI解析 関連記事表示のチェック状態の変更
   *
   * @param {boolean} checked チェックステータス
   */
  changeArticleCheckbox: ({ checked }: { checked: boolean }) => {
    return {
      action: "click",
      category: "checkBox",
      events: "AI解析/インサイトワード/関連記事表示",
      obj: {
        custom_vars: {
          checked: checked,
          ingest_version: 1,
          ingest_version_date: "2024-06-30"
        }
      }
    };
  },
  /**
   * AI解析 関連記事表示の欄にある追加絞り込み実行のボタンを選択
   *
   * @param {string} keyword キーワード
   */
  clickArticleAddSearch: ({ keyword }: { keyword: string }) => {
    return {
      action: "search",
      category: "button",
      events: "AI解析/インサイトワード/検索絞り込み",
      obj: {
        custom_vars: {
          keyword: keyword
        }
      }
    };
  },
  /**
   * コレクションフィード設定の媒体選択で選択媒体変更
   *
   * @param {string} collectionId コレクションID
   * @param {string} mediaCode 媒体コード
   * @param {string} checked 選択状態
   * @param {boolean} newCollection 新規コレクション
   */
  clickMediaCheckBox: ({
    collectionId,
    mediaCode,
    checked,
    newCollection
  }: {
    collectionId: string;
    mediaCode: string;
    checked: boolean;
    newCollection: boolean;
  }) => {
    const type = newCollection ? "作成" : "編集";
    return {
      action: "click",
      category: "checkBox",
      events: `コレクション/${type}/媒体選択`,
      obj: {
        custom_vars: {
          collection_id: collectionId,
          media_code: mediaCode,
          checked: checked,
          ingest_version: 1,
          ingest_version_date: "2024-06-30"
        }
      }
    };
  },
  /**
   * コレクションフィードのカードの保存アイコンを選択
   *
   * @param {string} collectionId コレクションID
   * @param {string} mediaCode 媒体コード
   * @param {string} sponsorName 提供元組織名
   *
   */
  clickCollectionFeedSaveIcon: ({
    collectionId,
    mediaCode,
    sponsorName
  }: {
    collectionId: string;
    mediaCode: string;
    sponsorName: string;
  }) => {
    return {
      action: "click",
      category: "button",
      events: "コレクション/保存/記事保存",
      obj: {
        custom_vars: {
          collection_id: collectionId,
          media_code: mediaCode,
          sponsor_name: sponsorName
        }
      }
    };
  },
  /**
   * コレクションフィードのカード選択(フィード記事が開いたとき)
   *
   * @param {string} collectionId コレクションID
   * @param {string} mediaCode 媒体コード
   * @param {string} sponsorName 提供元組織名
   *
   */
  clickCollectionFeedCard: ({
    collectionId,
    mediaCode,
    sponsorName
  }: {
    collectionId: string;
    mediaCode: string;
    sponsorName: string;
  }) => {
    return {
      action: "click",
      category: "card",
      events: "コレクション/フィード",
      obj: {
        custom_vars: {
          collection_id: collectionId,
          media_code: mediaCode,
          sponsor_name: sponsorName
        }
      }
    };
  },
  /**
   * 記事詳細ページの「続きを読む」ボタン選択
   *
   * @param {string} mediaCode 媒体コード
   * @param {string} sponsorName 提供元組織名
   *
   */
  clickCanonicalUrl: ({
    mediaCode,
    sponsorName
  }: {
    mediaCode: string;
    sponsorName: string;
  }) => {
    return {
      action: "click",
      category: "button",
      events: "記事詳細/続きを読む",
      obj: {
        custom_vars: {
          media_code: mediaCode,
          sponsor_name: sponsorName
        }
      }
    };
  },
  /**
   * 一括検索結果のコレクション保存ボタン選択時
   *
   * @param {string} mediaCode 媒体コード
   * @param {string} sponsorName 提供元組織名
   * @param {string} tabName タブ名
   *
   */
  clickGlobalSearchCollectionSaveIcon: ({
    mediaCode,
    sponsorName,
    tabName
  }: {
    mediaCode: string;
    sponsorName: string;
    tabName: string;
  }) => {
    return {
      action: "click",
      category: "button",
      events: `一括検索/${tabName}/コレクション保存`,
      obj: {
        custom_vars: {
          media_code: mediaCode,
          sponsor_name: sponsorName
        }
      }
    };
  },
  /**
   * 一括検索結果のコレクション保存時
   *
   * @param {string} tabName タブ名
   * @param {string} collectionId コレクションID
   * @param {string} mediaCode 媒体コード
   * @param {string} sponsorName 提供元組織名
   *
   */
  saveGlobalSearchCollection: ({
    mediaCode,
    sponsorName,
    tabName,
    collectionId
  }: {
    mediaCode: string;
    sponsorName: string;
    tabName: string;
    collectionId: string;
  }) => {
    return {
      action: "click",
      category: "button",
      events: `一括検索/${tabName}/コレクション保存/保存`,
      obj: {
        custom_vars: {
          collection_id: collectionId,
          media_code: mediaCode,
          sponsor_name: sponsorName
        }
      }
    };
  },
  /**
   * グラフ作成ページの反映ボタン押下
   */
  clickGraphCreate: () => {
    return {
      action: "click",
      category: "button",
      events: "便利ツール/グラフ作成/グラフ反映"
    };
  },
  /**
   * 記事詳細ページの「コレクションに追加」ボタンクリック時
   *
   * @param {string} mediaCode 媒体コード
   * @param {string} sponsorName 提供元組織名
   *
   */
  clickArticlePageAddCollectionButton: ({
    mediaCode,
    sponsorName
  }: {
    mediaCode: string;
    sponsorName: string;
  }) => {
    return {
      action: "click",
      category: "button",
      events: `記事詳細/コレクション保存`,
      obj: {
        custom_vars: {
          media_code: mediaCode,
          sponsor_name: sponsorName
        }
      }
    };
  },
  /**
   * 記事詳細ページのコレクション保存時
   *
   * @param {string} mediaCode 媒体コード
   * @param {string} sponsorName 提供元組織名
   * @param {string} collectionId コレクションID
   *
   */
  saveArticlePageCollection: ({
    mediaCode,
    sponsorName,
    collectionId
  }: {
    mediaCode: string;
    sponsorName: string;
    collectionId: string;
  }) => {
    return {
      action: "click",
      category: "button",
      events: `記事詳細/コレクション保存/保存`,
      obj: {
        custom_vars: {
          media_code: mediaCode,
          sponsor_name: sponsorName,
          collection_id: collectionId
        }
      }
    };
  },
  /**
   * 「検索入力補助モード切替」ボタンをクリック時
   *
   * @param {string} pageName ページ名
   * @param {string} condition 「有効化」or「無効化」
   *
   */
  clickAttentionModalButton: ({
    pageName,
    condition
  }: {
    pageName: string;
    condition: string;
  }) => {
    return {
      action: "click",
      category: "button",
      events: `${pageName}/検索入力補助モード切替/${condition}`
    };
  },
  /**
   * 「検索入力補助モード切替」の「有効にする」or「無効にする」ボタンをクリック時
   *
   * @param {string} pageName ページ名
   * @param {string} condition 「有効化」or「無効化」
   *
   */
  clickChangeAdvancedMode: ({
    pageName,
    condition
  }: {
    pageName: string;
    condition: string;
  }) => {
    return {
      action: "click",
      category: "button",
      events: `${pageName}/検索入力補助モード切替/${condition}/実行`
    };
  },
  /**
   * 個社ページ「グラフ編集」ボタンをクリック時
   *
   * @param {string} menuName 「メニュー名」
   * @param {string} tabName 「タブ名」
   * @param {string} title 「グラフタイトル」
   *
   */
  clickEditGraphButton: ({
    menuName,
    tabName,
    title
  }: {
    menuName: string;
    tabName: string;
    title: string;
  }) => {
    return {
      action: "click",
      category: "button",
      events: `個社/${menuName}/${tabName}/グラフ編集`,
      obj: {
        custom_vars: {
          title: title
        }
      }
    };
  },
  /**
   * 個社ページグラフ編集モーダルの「属性値をグラフに反映」ボタンをクリック時
   *
   * @param {string} menuName 「メニュー名」
   * @param {string} tabName 「タブ名」
   * @param {string} title 「グラフタイトル」
   *
   */
  clickAttributeValueReflectGraphButton: ({
    menuName,
    tabName,
    title
  }: {
    menuName: string;
    tabName: string;
    title: string;
  }) => {
    return {
      action: "click",
      category: "button",
      events: `個社/${menuName}/${tabName}/属性値をグラフに反映`,
      obj: {
        custom_vars: {
          title: title
        }
      }
    };
  },
  /**
   * 個社ページグラフ編集モーダルの「反映」ボタンをクリック時
   *
   * @param {string} title 「グラフタイトル」
   *
   */
  clickReflectGraphButton: ({ title }: { title: string }) => {
    return {
      action: "click",
      category: "button",
      events: "個社/グラフ集/グラフ編集/反映",
      obj: {
        custom_vars: {
          title: title
        }
      }
    };
  },
  /**
   * 時系列ダウンロードモーダルを開く「XLS」or「CSV」アイコンクリック時
   *
   * @param {string} type 「XLS」or「CSV」
   *
   */
  clickTimeSeriesDownloadModal: ({ type }: { type: string }) => {
    return {
      action: "click",
      category: "button",
      events: "時系列ダウンロード/モーダル/選択",
      obj: {
        custom_vars: {
          type: type
        }
      }
    };
  },
  /**
   * 個社ページグラフ編集モーダルの「属性値をグラフに反映」ボタンをクリック時
   *
   * @param {string} type チェックボックスの選択値
   * @param {string} periodFrom 時系列が選択された場合に表示される期間の開始期
   * @param {string} periodTo 時系列が選択された場合に表示される期間の開始期
   * @param {string} sortOrder 時系列が選択された場合に表示されるソート順の指定内容
   *
   */
  downloadTimeSeries: ({
    type,
    periodFrom,
    periodTo,
    sortOrder
  }: {
    type: string;
    periodFrom: string;
    periodTo: string;
    sortOrder: string;
  }) => {
    return {
      action: "download",
      category: "button",
      events: "時系列ダウンロード/モーダル/ダウンロード",
      obj: {
        custom_vars: {
          type: type,
          periodFrom: periodFrom,
          periodTo: periodTo,
          sortOrder: sortOrder
        }
      }
    };
  },
  /**
   * ファイルダウンロードの共通部分のイベント
   *
   * @param {string} type XLS/CSV/PDF/XRBLなどのファイルタイプ
   *
   */
  downloadFile: ({
    type,
    state,
    fileType
  }: {
    type: string;
    state?: "to-be-removed";
    fileType: string;
  }) => {
    return {
      action: "download",
      category: "system",
      events: "ファイルダウンロード",
      obj: {
        custom_vars: {
          url: location.href,
          type: type,
          state,
          file_type: fileType
        }
      }
    };
  },
  /**
   * ESG新着情報の記事一覧リンククリック時
   *
   * @param {string} pageName 「新着記事」or「適時開示」
   * @param {string} linkName 「***の記事一覧を見る」（環境・社会・ガバナンス）
   *
   */
  clickEsgArticleNewsListLink: ({
    pageName,
    linkName
  }: {
    pageName: string;
    linkName: string;
  }) => {
    return {
      action: "click",
      category: "link",
      events: `ESG総合/ESG新着情報/${pageName}/${linkName}`
    };
  },
  /**
   * ESG新着情報の各記事クリック時
   *
   * @param {string} pageName 「新着記事」or「適時開示」
   * @param {string} esgCategory （環境・社会・ガバナンス）
   *
   */
  clickEsgArticleNews: ({
    pageName,
    esgCategory
  }: {
    pageName: string;
    esgCategory: string;
  }) => {
    return {
      action: "click",
      category: "link",
      events: `ESG総合/ESG新着情報/${pageName}`,
      obj: {
        custom_vars: {
          esg_category: esgCategory
        }
      }
    };
  },
  /**
   * ESG新着情報の「適時開示の一覧を見る」ボタンクリック時
   */
  clickEsgArticleDisclosure: () => {
    return {
      action: "click",
      category: "button",
      events: "ESG総合/ESG新着情報/適時開示/適時開示の一覧を見る"
    };
  },
  /**
   * ESG新着情報の適時開示の各資料見出しクリック時
   *
   * @param {string} title クリックした開示資料の見出し
   *
   */
  clickEsgDisclosureMaterials: ({ title }: { title: string }) => {
    return {
      action: "click",
      category: "button",
      events: "ESG総合/ESG新着情報/適時開示",
      obj: {
        custom_vars: {
          title: title
        }
      }
    };
  },
  /**
   * ESG新着情報のテーマ別企業比較PDFダウンロードボタンクリック時
   *
   * @param {string} title テーマ名(CO2排出量/働きやすさ/役員構成/ストックオプション導入状況)
   *
   */
  clickEsgResultPdfDownload: ({ title }: { title: string }) => {
    return {
      action: "click",
      category: "button",
      events: "ESG総合/テーマ別企業比較",
      obj: {
        custom_vars: {
          title: title
        }
      }
    };
  },
  /**
   * テーマ別企業比較の「NEEDS業種分類を選択」クリック時
   *
   * @param {string} needsIndustrySCode 選択中の業種コード
   * @param {string} needsIndustrySName 選択中の業種名
   *
   */
  clickEsgIndustrySelectButton: ({
    needsIndustrySCode,
    needsIndustrySName
  }: {
    needsIndustrySCode: string;
    needsIndustrySName: string;
  }) => {
    return {
      action: "click",
      category: "button",
      events: "ESG総合/テーマ別企業比較/NEEDS業種分類を選択",
      obj: {
        custom_vars: {
          needs_industry_s_code: needsIndustrySCode,
          needs_industry_s_name: needsIndustrySName
        }
      }
    };
  },
  /**
   * テーマ別企業比較の「項目・企業を追加して比較」ボタンクリック時
   */
  clickEsgCompanyComparison: () => {
    return {
      action: "click",
      category: "link",
      events: "ESG総合/テーマ別企業比較/企業比較ツールに遷移"
    };
  },
  /**
   * マテリアリティ検索の各キーワードクリック時
   *
   * @param {string} keyword クリックされたキーワード
   *
   */
  clickEsgMaterialityKeyword: ({ keyword }: { keyword: string }) => {
    return {
      action: "click",
      category: "button",
      events: "ESG総合/マテリアリティから検索",
      obj: {
        custom_vars: {
          keyword: keyword
        }
      }
    };
  },
  /**
   * ESG新着情報のタブ切り替え時
   *
   * @param {string} pageTitle 「ESG新着情報」or「適時開示」
   * @param {string} tabName クリックしたタブの名前
   *
   */
  clickEsgChangeTab: ({
    pageTitle,
    tabName
  }: {
    pageTitle: string;
    tabName: string;
  }) => {
    return {
      action: "click",
      category: "button",
      events: `ESG総合/${pageTitle}/${tabName}`
    };
  },
  /**
   * ESGテーマ別企業比較のダウンロードボタンクリック時
   *
   * @param {title} title テーマ名(CO2排出量/働きやすさ/役員構成/ストックオプション導入状況)
   * @param {string} type 「XLS」or「CSV」
   *
   */
  clickEsgDownloadButton: ({
    title,
    type
  }: {
    title: string;
    type: string;
  }) => {
    return {
      action: "click",
      category: "button",
      events: "ESG総合/テーマ別企業比較",
      obj: {
        custom_vars: {
          title: title,
          type: type
        }
      }
    };
  },
  /**
   * ニュース記事・雑誌 検索実行時
   *
   * @param {string} terms キーワード
   */
  clickArticleNewsSearch: ({ terms }: { terms: string }) => {
    return {
      action: "click",
      category: "button",
      events: "ニュース記事・雑誌/検索実行",
      obj: {
        custom_vars: {
          terms: terms
        }
      }
    };
  },
  /**
   * 開示資料 検索実行時
   *
   * @param {string} terms キーワード
   */
  clickArticleDisclosureSearch: ({ terms }: { terms: string }) => {
    return {
      action: "click",
      category: "button",
      events: "開示資料/検索実行",
      obj: {
        custom_vars: {
          terms: terms
        }
      }
    };
  },
  /**
   * サマリタブの「検索結果をすべて表示」を押下時
   *
   * @param {string} contentName コンテンツ名
   */
  clickSummaryShowAll: ({ contentName }: { contentName: string }) => {
    return {
      action: "click",
      category: "button",
      events: "検索結果をすべて表示",
      obj: {
        custom_vars: {
          content: contentName
        }
      }
    };
  },
  /**
   * 企業リスト作成 詳細条件から作成>保存済み条件呼び出しボタン押下時
   */
  clickScreeningCorpItemSavedButton: () => {
    return {
      action: "click",
      category: "button",
      events: "企業検索/詳細条件から作成/保存済み条件呼び出し"
    };
  },
  /**
   * 企業リスト作成 詳細条件から作成>「保存済み条件呼び出す」モーダル>呼び出しボタン押下時
   *
   * @param {string} contentId コンテンツID
   */
  clickScreeningCorpItemSavedModalSubmitButton: (contentId: string) => {
    return {
      action: "click",
      category: "button",
      events:
        "企業検索/詳細条件から作成/保存済み条件呼び出しモーダル/保存済み条件呼び出し",
      obj: {
        custom_vars: {
          content_id: contentId
        }
      }
    };
  },
  /**
   * 企業リスト作成 詳細条件から作成>条件を保存ボタン押下時
   */
  clickScreeningCorpItemSavingButton: () => {
    return {
      action: "click",
      category: "button",
      events: "企業検索/詳細条件から作成/条件保存"
    };
  },
  /**
   * 企業リスト作成 詳細条件から作成>「条件を保存」モーダル>保存ボタン押下時
   *
   * @param {string} contentId コンテンツID
   */
  clickScreeningCorpItemSavingModalSubmitButton: (contentId: string) => {
    return {
      action: "click",
      category: "button",
      events: "企業検索/詳細条件から作成/条件保存モーダル/保存",
      obj: {
        custom_vars: {
          content_id: contentId
        }
      }
    };
  },
  /**
   * 企業リスト作成 詳細条件から作成>条件設定ボタン押下時
   */
  clickScreeningCorpItemFilterButton: () => {
    return {
      action: "click",
      category: "button",
      events: "企業検索/詳細条件から作成/条件設定"
    };
  },
  /**
   * 企業リスト作成 詳細条件から作成>「絞り込み条件」モーダル>選択済み項目を表示ボタン押下時
   */
  clickScreeningCorpItemFilterModalSelectedItemButton: () => {
    return {
      action: "click",
      category: "link",
      events:
        "企業検索/詳細条件から作成/絞り込み条件選択モーダル/選択済み項目表示"
    };
  },
  /**
   * 企業リスト作成 詳細条件から作成>「絞り込み条件」モーダル>絞り込み条件を設定ボタン押下時
   */
  clickScreeningCorpItemFilterModalSubmitButton: () => {
    return {
      action: "click",
      category: "button",
      events: "企業検索/詳細条件から作成/絞り込み条件選択モーダル/設定"
    };
  },
  /**
   * 企業リスト作成 詳細条件から作成>表示項目ボタン押下時
   */
  clickScreeningCorpItemDisplayItemsButton: () => {
    return {
      action: "click",
      category: "button",
      events: "企業検索/詳細条件から作成/表示項目設定"
    };
  },
  /**
   * 企業リスト作成 詳細条件から作成>「表示項目選択」モーダル>選択済み項目を表示ボタン押下時
   */
  clickScreeningCorpItemDisplayModalSelectedItemButton: () => {
    return {
      action: "click",
      category: "link",
      events: "企業検索/詳細条件から作成/表示項目選択モーダル/選択済み項目表示"
    };
  },
  /**
   * 企業リスト作成 詳細条件から作成>「表示項目選択」モーダル>絞り込み条件を設定ボタン押下時
   */
  clickScreeningCorpItemDisplayModalSubmitButton: () => {
    return {
      action: "click",
      category: "button",
      events: "企業検索/詳細条件から作成/表示項目選択モーダル/設定"
    };
  },
  /**
   * 企業リスト作成 詳細条件から作成>企業選択ボタン押下時
   */
  clickScreeningCorpItemCorpModalButton: () => {
    return {
      action: "click",
      category: "button",
      events: "企業検索/詳細条件から作成/企業選択"
    };
  },
  /**
   * 企業リスト作成 詳細条件から作成>「企業選択」モーダル>選択済み項目表示ボタン押下時
   */
  clickScreeningCorpItemCorpModalSelectedItemButton: () => {
    return {
      action: "click",
      category: "link",
      events: "企業検索/詳細条件から作成/企業選択モーダル/選択済み項目表示"
    };
  },
  /**
   * 企業リスト作成 詳細条件から作成>「企業選択」モーダル>チェックした企業を追加押下時
   */
  clickScreeningCorpItemCorpModalSubmitButton: () => {
    return {
      action: "click",
      category: "button",
      events: "企業検索/詳細条件から作成/企業選択モーダル/追加"
    };
  },
  /**
   * 企業リスト作成 詳細条件から作成>条件クリア
   */
  clickScreeningCorpItemConditionClearButton: () => {
    return {
      action: "click",
      category: "button",
      events: "企業検索/詳細条件から作成/条件クリア"
    };
  },
  /**
   * 企業リスト作成 詳細条件から作成>結果表示ボタン
   */
  clickScreeningCorpItemSubmitButton: () => {
    return {
      action: "click",
      category: "button",
      events: "企業検索/詳細条件から作成/結果表示"
    };
  },
  /**
   * 企業リスト作成 詳細条件から作成>リスト作成結果>有報・短信
   */
  clickScreeningCorpItemResultYtConditionButton: () => {
    return {
      action: "click",
      category: "button",
      events: "企業検索/詳細条件から作成/リスト作成結果（企業）/有報・短信"
    };
  },
  /**
   * 企業リスト作成 詳細条件から作成>リスト作成結果>連結・単独
   */
  clickScreeningCorpItemResultScConditionButton: () => {
    return {
      action: "click",
      category: "button",
      events: "企業検索/詳細条件から作成/リスト作成結果（企業）/連結・単独"
    };
  },
  /**
   * 企業リスト作成 詳細条件から作成>リスト作成結果>連結・単独
   */
  clickScreeningCorpItemResultCurrencyButton: () => {
    return {
      action: "click",
      category: "button",
      events: "企業検索/詳細条件から作成/リスト作成結果（企業）/通貨単位"
    };
  },
  /**
   * 企業リスト作成 詳細条件から作成>リスト作成結果>LTM
   */
  clickScreeningCorpItemResultLtmButton: () => {
    return {
      action: "click",
      category: "button",
      events: "企業検索/詳細条件から作成/リスト作成結果（企業）/LTM"
    };
  },
  /**
   * 企業リストのタブクリック
   * @param {string} tabName 対象タブ名
   */
  companyListTabChange: ({ tabName }: { tabName: string }) => {
    return {
      action: "click",
      category: "button",
      events: `スクリーニング（企業）/${tabName}`
    };
  },
  /**
   * 企業比較のタブ切り替え
   * @param {string} tabName 対象タブ名
   */
  companyComparisonTabChange: ({ tabName }: { tabName: string }) => {
    return {
      action: "click",
      category: "button",
      events: `企業比較/${tabName}`
    };
  },
  /**
   * 企業比較の結果の散布図へクリック
   * @param {string} tabName 対象タブ名
   */
  companyComparisonScatterPlot: ({ tabName }: { tabName: string }) => {
    return {
      action: "click",
      category: "button",
      events: `比較表_結果表示/${tabName}/散布図表示`
    };
  },
  /**
   * 業界/分類から絞り込むのタブ切り替え
   * @param {string} filter 絞り込み元（「業界」「分類」のいずれか）
   * @param {string} level 階層(「業界中分類」「業界小分類」「企業名」のいずれか)
   * @param {string} info 遷移先（「業界情報」「企業情報」のいずれか）
   */
  filterIndustryOnClickLink: ({
    filter,
    level,
    info
  }: {
    filter: string;
    level: string;
    info: string;
  }) => {
    return {
      action: "click",
      category: "button",
      events: `${filter}/${level}/${info}へ`
    };
  },
  /**
   * 業界リスト作成
   * 条件を保存
   */
  industryListSaveCondition: () => {
    return {
      action: "click",
      category: "button",
      events: "スクリーニング(業界)/条件を保存"
    };
  },
  /**
   * 企業比較の結果タブ切り替え
   * @param {string} tabName 対象タブ名
   */
  companyComparisonResultTabChange: ({
    tabName,
    comparisonTab
  }: {
    tabName: string;
    comparisonTab: string;
  }) => {
    return {
      events: `比較表_結果表示/${tabName}`,
      obj: {
        custom_vars: {
          tab_id: comparisonTab
        }
      }
    };
  },
  /**
   * 企業リスト作成 詳細条件から作成>リスト作成結果>タブ
   */
  clickScreeningCorpItemResultTabButton: ({
    type
  }: {
    type: "企業" | "セグメント";
  }) => {
    return {
      action: "click",
      category: "button",
      events: `企業検索/詳細条件から作成/リスト作成結果（${type}）`
    };
  },
  /**
   * 企業リスト作成 詳細条件から作成>リスト作成結果>日経予想
   */
  clickScreeningCorpItemResultExpectButton: () => {
    return {
      action: "click",
      category: "button",
      events: "企業検索/詳細条件から作成/リスト作成結果（企業）/日経予想"
    };
  },
  /**
   * 業界リスト作成
   * 散布図へ
   */
  industryListScatterPlotDisplay: () => {
    return {
      action: "click",
      category: "button",
      events: "比較表_結果表示/散布図表示"
    };
  },
  /**
   * 企業リスト作成 新旧UIの切り替え
   */
  clickScreeningCorpItemChangeDesign: ({ isNew }: { isNew: boolean }) => {
    return {
      action: "click",
      category: "button",
      events: `企業検索/詳細条件から作成/${isNew ? "新UI" : "旧UI"}表示`
    };
  },
  /**
   * 日経企業活動情報 その他の検索条件を開く
   */
  clickArticleStrategyOpenCollapsed: () => {
    return {
      action: "click",
      category: "button",
      events: "企業活動情報/その他の検索条件"
    };
  },
  /**
   * 日経企業活動情報 詳細条件を閉じる
   */
  clickArticleStrategyCloseCollapsed: () => {
    return {
      action: "click",
      category: "button",
      events: "企業活動情報/詳細検索を閉じる"
    };
  },
  /**
   * 日経企業活動情報 検索ボタン押下時
   */
  clickArticleStrategyResultButton: ({
    terms,
    terms_detail
  }: {
    terms: string;
    terms_detail: {
      needsInfo1: string;
      needsInfo2: string;
      needsInfo3: string;
    };
  }) => {
    return {
      action: "click",
      category: "button",
      events: "企業活動情報/検索実行",
      obj: {
        custom_vars: {
          terms,
          terms_detail
        }
      }
    };
  },
  /**
   * 業界検索 簡易検索 結果ボタン押下時
   */
  clickIndustrySearchResultButton: ({
    terms,
    terms_detail
  }: {
    terms: string;
    terms_detail: {
      needsIndustryName: string;
      industrySearchTargetName: string;
    };
  }) => {
    return {
      action: "click",
      category: "button",
      events: "企業活動情報/検索実行",
      obj: {
        custom_vars: {
          terms,
          terms_detail
        }
      }
    };
  },

  /**
   * 企業アラート設定モーダル
   */
  clickAlertSaveButton: ({
    pageName = "",
    category1 = "",
    category2 = "",
    category3 = ""
  }) => {
    return {
      action: "click",
      category: "button",
      events: "企業アラート設定保存",
      obj: {
        custom_vars: {
          page_name: pageName,
          page_num: 1,
          category_l1: category1,
          category_l2: category2,
          category_l3: category3,
          ingest_version: 1,
          ingest_version_date: "2024-08-27"
        }
      }
    };
  },

  /**
   * 一括検索のヘルプクリック時
   */
  clickHelpIcon: ({
    pageName = "",
    category1 = "",
    category2 = "",
    category3 = ""
  }) => {
    return {
      action: "click",
      category: "button",
      events: "ヘルプサイドバー表示",
      obj: {
        custom_vars: {
          page_name: pageName,
          page_num: 1,
          category_l1: category1,
          category_l2: category2,
          category_l3: category3,
          ingest_version: 1,
          ingest_version_date: "2024-08-27"
        }
      }
    };
  },

  /**
   * アラート設定のヘルプクリック時
   */
  clickAlertHelpIcon: ({
    pageName = "",
    category1 = "",
    category2 = "",
    category3 = ""
  }) => {
    return {
      action: "click",
      category: "button",
      events: "ヘルプサイドバー表示（アラート）",
      obj: {
        custom_vars: {
          page_name: pageName,
          page_num: 1,
          category_l1: category1,
          category_l2: category2,
          category_l3: category3,
          ingest_version: 1,
          ingest_version_date: "2024-08-27"
        }
      }
    };
  },

  /**
   *「企業を追加」クリック時
   */
  clickAlertAddCorpButton: ({
    pageName = "",
    category1 = "",
    category2 = "",
    category3 = ""
  }) => {
    return {
      action: "click",
      category: "button",
      events: "企業選択モーダル表示",
      obj: {
        custom_vars: {
          page_name: pageName,
          page_num: 1,
          category_l1: category1,
          category_l2: category2,
          category_l3: category3,
          ingest_version: 1,
          ingest_version_date: "2024-08-27"
        }
      }
    };
  },

  /**
   *「設定を変更（まとめて設定）」クリック時
   */
  clickAlertUpdateCorpButton: ({
    pageName = "",
    category1 = "",
    category2 = "",
    category3 = ""
  }) => {
    return {
      action: "click",
      category: "button",
      events: "企業アラート設定変更",
      obj: {
        custom_vars: {
          page_name: pageName,
          page_num: 1,
          category_l1: category1,
          category_l2: category2,
          category_l3: category3,
          ingest_version: 1,
          ingest_version_date: "2024-08-27"
        }
      }
    };
  },

  /**
   * 企業選択モーダル「チェックした企業を追加」クリック時
   */
  clickAlertConfirmAddCorpButton: ({
    tab_id = "不明なタグ",
    pageName = "",
    category1 = "",
    category2 = "",
    category3 = ""
  }) => {
    return {
      action: "click",
      category: "button",
      events: "チェックした企業の企業アラートを設定（設定画面表示）",
      obj: {
        custom_vars: {
          page_name: pageName,
          page_num: 1,
          category_l1: category1,
          category_l2: category2,
          category_l3: category3,
          ingest_version: 1,
          ingest_version_date: "2024-08-27",
          tab_id
        }
      }
    };
  },

  /**
   * アラート設定ボタンクリック時
   */
  clickAlertSettingButton: ({
    pageName = "",
    category1 = "",
    category2 = "",
    category3 = ""
  }) => {
    return {
      action: "click",
      category: "button",
      events: "企業アラート設定表示",
      obj: {
        custom_vars: {
          page_name: pageName,
          page_num: 1,
          category_l1: category1,
          category_l2: category2,
          category_l3: category3,
          ingest_version: 1,
          ingest_version_date: "2024-08-27"
        }
      }
    };
  },

  /**
   * コレクションキーワード検索
   */
  myCollectionSearchKeyword: (andKeywords: string) => {
    return {
      action: "submit",
      category: "input",
      events: "検索キーワード",
      obj: {
        custom_vars: {
          andKeywords,
          ingest_version: 1,
          ingest_version_date: "2024-10-29"
        }
      }
    };
  },

  /**
   * コレクションクリック項目選択ボックス
   */
  myCollectionLimitClick: () => {
    return {
      action: "click",
      category: "selectBox",
      events: "件数リストを開く",
      obj: {
        custom_vars: {
          ingest_version: 1,
          ingest_version_date: "2024-10-29"
        }
      }
    };
  },

  /**
   * コレクション選択数の変更
   */
  myCollectionLimitChange: (selected: string) => {
    return {
      action: "click",
      category: "selectBox",
      events: "件数を選択",
      obj: {
        custom_vars: {
          selected,
          ingest_version: 1,
          ingest_version_date: "2024-10-29"
        }
      }
    };
  },

  /**
   * コレクションをクリックして並べ替える
   */
  myCollectionSortClick: () => {
    return {
      action: "click",
      category: "selectBox",
      events: "表示順リストを開く",
      obj: {
        custom_vars: {
          ingest_version: 1,
          ingest_version_date: "2024-10-29"
        }
      }
    };
  },

  /**
   * コレクション選択のソート方法
   */
  myCollectionSortChange: (selected: string) => {
    return {
      action: "click",
      category: "selectBox",
      events: "表示順を選択",
      obj: {
        custom_vars: {
          selected,
          ingest_version: 1,
          ingest_version_date: "2024-10-29"
        }
      }
    };
  },

  /**
   * コレクションアラーム設定
   */
  myCollectionMailClick: ({
    id,
    name,
    events
  }: {
    id: string;
    name: string;
    events: string;
  }) => {
    return {
      action: "click",
      category: "button",
      events,
      obj: {
        custom_vars: {
          id,
          name,
          ingest_version: 2,
          ingest_version_date: "2024-11-26"
        }
      }
    };
  },

  /**
   * コレクションの共有設定
   */
  myCollectionShareClick: ({ id, name }: { id: string; name: string }) => {
    return {
      action: "click",
      category: "button",
      events: "コレクション共有",
      obj: {
        custom_vars: {
          id,
          name,
          ingest_version: 3,
          ingest_version_date: "2024-12-17"
        }
      }
    };
  },

  /**
   * コレクション共有完了
   */

  myCollectionShareSave: ({ id, events }: { id: string; events: string }) => {
    return {
      action: "click",
      category: "button",
      events: events,
      obj: {
        custom_vars: {
          id,
          ingest_version: 2,
          ingest_version_date: "2024-12-17"
        }
      }
    };
  },

  /**
   * その他の操作の場合はコレクションをクリック
   */
  myCollectionOtherClick: ({ id, name }: { id: string; name: string }) => {
    return {
      action: "click",
      category: "button",
      events: "その他の操作",
      obj: {
        custom_vars: {
          id,
          name,
          ingest_version: 1,
          ingest_version_date: "2024-10-29"
        }
      }
    };
  },

  /**
   * コレクションカードモード
   */
  myCollectionCardClick: () => {
    return {
      action: "click",
      category: "button",
      events: "タイルビュー表示",
      obj: {
        custom_vars: {
          ingest_version: 1,
          ingest_version_date: "2024-10-29"
        }
      }
    };
  },

  /**
   * コレクションリストモード
   */
  myCollectionListClick: () => {
    return {
      action: "click",
      category: "button",
      events: "リストビュー表示",
      obj: {
        custom_vars: {
          ingest_version: 1,
          ingest_version_date: "2024-10-29"
        }
      }
    };
  },

  /**
   * 新規コレクション作成
   */
  myCollectionCreate: () => {
    return {
      action: "click",
      category: "button",
      events: "新規コレクション作成",
      obj: {
        custom_vars: {
          ingest_version: 1,
          ingest_version_date: "2024-10-29"
        }
      }
    };
  },

  /**
   * 「コレクションインポート」ボタンをクリックした場合
   */
  myCollectionImportClick: () => {
    return {
      action: "click",
      category: "button",
      events: "インポート",
      obj: {
        custom_vars: {
          ingest_version: 1,
          ingest_version_date: "2024-10-29"
        }
      }
    };
  },

  /**
   * 選択した Excel ファイルをコレクションをインポートします
   */
  myCollectionExcelClick: () => {
    return {
      action: "click",
      category: "button",
      events: "インポートファイル選択",
      obj: {
        custom_vars: {
          ingest_version: 1,
          ingest_version_date: "2024-10-29"
        }
      }
    };
  },

  /**
   * コレクションインポート実行時
   */
  myCollectionImportSave: () => {
    return {
      action: "click",
      category: "button",
      events: "インポート実行",
      obj: {
        custom_vars: {
          ingest_version: 1,
          ingest_version_date: "2024-10-29"
        }
      }
    };
  },

  /**
   * コレクションのインポートがキャンセルされた場合
   */
  myCollectionImportCancel: () => {
    return {
      action: "click",
      category: "button",
      events: "インポートキャンセル",
      obj: {
        custom_vars: {
          ingest_version: 1,
          ingest_version_date: "2024-10-29"
        }
      }
    };
  },

  /**
   * 補足情報を収集してクリックする場合
   */
  myCollectionMoreInfoClick: () => {
    return {
      action: "click",
      category: "button",
      events: "補足情報を読む",
      obj: {
        custom_vars: {
          ingest_version: 1,
          ingest_version_date: "2024-10-29"
        }
      }
    };
  },

  /**
   * 補足情報 収集を終了する場合
   */
  myCollectionMoreInfoClose: () => {
    return {
      action: "click",
      category: "button",
      events: "補足情報を閉じる",
      obj: {
        custom_vars: {
          ingest_version: 1,
          ingest_version_date: "2024-10-29"
        }
      }
    };
  },

  /**
   * コレクションの情報、編集、エンタープライズ選択をクリック
   */
  myCollectionCorpSelect: ({ action }: { action: string }) => {
    return {
      action: "click",
      category: "button",
      events: "企業選択",
      obj: {
        custom_vars: {
          action,
          ingest_version: 1,
          ingest_version_date: "2024-10-29"
        }
      }
    };
  },

  /**
   * 企業がタブの切り替えを選択したときに情報を収集および編集します
   */
  myCollectionCorpSelectSwitchTab: ({
    tabName,
    action
  }: {
    tabName: string;
    action: string;
  }) => {
    return {
      action: "click",
      category: "button",
      events: tabName,
      obj: {
        custom_vars: {
          action,
          ingest_version: 1,
          ingest_version_date: "2024-10-29"
        }
      }
    };
  },

  /**
   * コレクションの編集 会社を選択 クリックして選択した会社を表示
   */
  myCollectionCorpSelectShowSelectedItems: ({ action }: { action: string }) => {
    return {
      action: "click",
      category: "link",
      events: "選択済み項目表示",
      obj: {
        custom_vars: {
          action,
          ingest_version: 1,
          ingest_version_date: "2024-10-29"
        }
      }
    };
  },

  /**
   * 会社をお気に入りとして編集する際に保存を選択した場合
   */
  myCollectionCorpSelectSave: ({ action }: { action: string }) => {
    return {
      action: "click",
      category: "button",
      events: "企業を設定",
      obj: {
        custom_vars: {
          action,
          ingest_version: 1,
          ingest_version_date: "2024-10-29"
        }
      }
    };
  },

  /**
   * 会社をお気に入りとして編集する際に保存を選択した場合
   */
  myCollectionCorpSelectReset: ({ action }: { action: string }) => {
    return {
      action: "click",
      category: "button",
      events: "条件クリア",
      obj: {
        custom_vars: {
          action,
          ingest_version: 1,
          ingest_version_date: "2024-10-29"
        }
      }
    };
  },

  /**
   * コレクション編集条件指定
   */
  myCollectionConditionalClick: ({ action }: { action: string }) => {
    return {
      action: "click",
      category: "button",
      events: "フィード条件選択",
      obj: {
        custom_vars: {
          action,
          ingest_version: 1,
          ingest_version_date: "2024-10-29"
        }
      }
    };
  },

  /**
   * コレクション キーワード設定拡張時
   */
  myCollectionKeywordClick: ({ action }: { action: string }) => {
    return {
      action: "click",
      category: "button",
      events: "キーワードを設定する",
      obj: {
        custom_vars: {
          action,
          ingest_version: 1,
          ingest_version_date: "2024-10-29"
        }
      }
    };
  },

  /**
   * コレクション メディア設定展開時
   */
  myCollectionMediaClick: ({ action }: { action: string }) => {
    return {
      action: "click",
      category: "button",
      events: "媒体を設定する",
      obj: {
        custom_vars: {
          action,
          ingest_version: 1,
          ingest_version_date: "2024-10-29"
        }
      }
    };
  },

  /**
   * コレクション補足情報を編集展開/閉じる
   */
  myCollectionEditMoreClick: () => {
    return {
      action: "click",
      category: "button",
      events: "補足情報トグル開閉",
      obj: {
        custom_vars: {
          ingest_version: 1,
          ingest_version_date: "2024-10-29"
        }
      }
    };
  },

  /**
   * コレクション編集 共有設定を確認・解除する場合
   */
  myCollectionEditShareCheck: () => {
    return {
      action: "click",
      category: "checkbox",
      events: "共有設定",
      obj: {
        custom_vars: {
          ingest_version: 1,
          ingest_version_date: "2024-10-29"
        }
      }
    };
  },

  /**
   * コレクション作成 or 保存
   */
  myCollectionSave: ({
    binderId,
    type
  }: {
    binderId?: string;
    type: string;
  }) => {
    return {
      action: "click",
      category: "button",
      events: type,
      obj: {
        custom_vars: {
          id: binderId || "",
          ingest_version: 3,
          ingest_version_date: "2024-12-17"
        }
      }
    };
  },

  /**
   * 各ページ内リンクをクリック時のイベントを追加する
   */
  clickHomeNavigation: (name: string) => {
    return {
      action: "click",
      category: "button",
      events: "ページ内リンククリック",
      obj: {
        custom_vars: {
          target: name,
          ingest_version: 1,
          ingest_version_date: "2024-11-26"
        }
      }
    };
  },

  /**
   * コレクション編集
   */
  myCollectionEditSave: () => {
    return {
      action: "click",
      category: "button",
      events: "コレクション編集",
      obj: {
        custom_vars: {
          ingest_version: 1,
          ingest_version_date: "2024-12-10"
        }
      }
    };
  },

  /**
   * コレクション複製
   */
  myCollectionClone: () => {
    return {
      action: "click",
      category: "button",
      events: "コレクション複製",
      obj: {
        custom_vars: {
          ingest_version: 1,
          ingest_version_date: "2024-12-10"
        }
      }
    };
  },

  /**
   * コレクション複製完了
   */
  myCollectionCloneComplete: (binderId: string) => {
    return {
      action: "click",
      category: "button",
      events: "コレクション作成（複製）完了",
      obj: {
        custom_vars: {
          id: binderId,
          ingest_version: 1,
          ingest_version_date: "2024-12-10"
        }
      }
    };
  },

  /**
   * コレクション削除
   */
  myCollectionDelete: () => {
    return {
      action: "click",
      category: "button",
      events: "コレクション削除",
      obj: {
        custom_vars: {
          ingest_version: 1,
          ingest_version_date: "2024-12-10"
        }
      }
    };
  },

  /**
   * コレクション削除完了
   */
  myCollectionDeleteComplete: (binderId: string) => {
    return {
      action: "click",
      category: "button",
      events: "コレクション削除完了",
      obj: {
        custom_vars: {
          id: binderId,
          ingest_version: 1,
          ingest_version_date: "2024-12-10"
        }
      }
    };
  },

  /**
   * コレクション公開
   */
  myCollectionPublish: () => {
    return {
      action: "click",
      category: "button",
      events: "コレクション公開",
      obj: {
        custom_vars: {
          ingest_version: 1,
          ingest_version_date: "2024-12-17"
        }
      }
    };
  },

  /**
   * コレクション公開完了
   */
  myCollectionPublishComplete: (binderId: string) => {
    return {
      action: "click",
      category: "button",
      events: "コレクション公開完了",
      obj: {
        custom_vars: {
          id: binderId,
          ingest_version: 1,
          ingest_version_date: "2024-12-17"
        }
      }
    };
  },

  /**
   * コレクション追加
   */
  collectionAddtion: () => {
    return {
      action: "click",
      category: "button",
      events: "コレクション追加",
      obj: {
        custom_vars: {
          ingest_version: 1,
          ingest_version_date: "2024-12-17"
        }
      }
    };
  },

  /**
   * コレクションを作成して登録
   */
  collectionAddtionCreate: () => {
    return {
      action: "click",
      category: "button",
      events: "コレクションを作成して登録",
      obj: {
        custom_vars: {
          ingest_version: 1,
          ingest_version_date: "2024-12-17"
        }
      }
    };
  },

  /**
   * コレクション保存完了
   */
  collectionAddtionSave: (binderId: string) => {
    return {
      action: "click",
      category: "button",
      events: "コレクション保存完了",
      obj: {
        custom_vars: {
          id: binderId,
          ingest_version: 1,
          ingest_version_date: "2024-12-17"
        }
      }
    };
  },

  /**
   * レポート作成（企業レポート）
   */
  clickCorpReportCreator: () => {
    return {
      action: "click",
      category: "button",
      events: "レポート作成（企業レポート）",
      obj: {
        custom_vars: {
          action: {
            location: location.href,
            destination: ""
          },
          ingest_version: 1,
          ingest_version_date: "2025-02-05"
        }
      }
    };
  },

  /**
   * レポートダウンロード（企業レポート）
   */
  clickCorpReportDownload: () => {
    return {
      action: "click",
      category: "button",
      events: "ダウンロード（企業レポート）",
      obj: {
        custom_vars: {
          action: {
            location: location.href,
            destination: ""
          },
          ingest_version: 1,
          ingest_version_date: "2025-02-05"
        }
      }
    };
  },

  /**
   * 詳細レポートダウンロード
   */
  clickIndustryReportDetail: ({ destination = "", industryHierarchy = "" }) => {
    return {
      action: "click",
      category: "button",
      events: "詳細レポートダウンロード（日経業界分析レポート）",
      obj: {
        custom_vars: {
          action: {
            location: location.href,
            destination
          },
          industry: industryHierarchy,
          ingest_version: 1,
          ingest_version_date: "2025-02-05"
        }
      }
    };
  },

  /**
   * 詳細レポートダウンロード（日経業界分析レポート）
   * 記事リストまたはその他の場所
   */
  clickIndustryReportDownloadByArticle: ({ destination = "" }) => {
    return {
      action: "click",
      category: "button",
      events: "詳細レポートダウンロード（日経業界分析レポート）",
      obj: {
        custom_vars: {
          action: {
            location: location.href,
            destination
          },
          ingest_version: 1,
          ingest_version_date: "2025-02-05"
        }
      }
    };
  },

  /**
   * 企業レポート
   *
   * @param {string} type ファイル形式（PPT,WORD等）
   * @param {string} file_type レポートの種類を設定
   * @param {string} file_name ファイル名（～.ppt等）
   * @param {string} title レポートの見出し
   */
  downloadCorpReportComplete: ({
    type,
    fileType,
    fileName,
    title
  }: {
    type: string;
    fileType: string;
    fileName: string;
    title: string;
  }) => {
    return {
      action: "download",
      category: "system",
      events: "ファイルダウンロード",
      obj: {
        custom_vars: {
          url: location.href,
          type: type,
          file_type: fileType,
          file_name: fileName,
          title,
          ingest_version: 1,
          ingest_version_date: "2025-02-05"
        }
      }
    };
  },

  /**
   * 日経業界分析レポート
   *
   * @param {string} type ファイル形式（PPT,WORD等）
   * @param {string} file_type レポートの種類を設定
   * @param {string} file_name ファイル名（～.ppt等）
   * @param {string} title レポートの見出し
   */
  downloadVsIndustryAnalysisReportComplete: ({
    type,
    fileType,
    fileName,
    title
  }: {
    type: string;
    fileType: string;
    fileName: string;
    title: string;
  }) => {
    return {
      action: "download",
      category: "system",
      events: "ファイルダウンロード",
      obj: {
        custom_vars: {
          url: location.href,
          type: type,
          file_type: fileType,
          file_name: fileName,
          title,
          ingest_version: 1,
          ingest_version_date: "2025-02-05"
        }
      }
    };
  },

  /**
   * レポート作成（業界解説レポート）
   */
  clickIndustryReportCreator: ({
    destination = "",
    industryHierarchy = ""
  }) => {
    return {
      action: "click",
      category: "button",
      events: "レポート作成（業界解説レポート）",
      obj: {
        custom_vars: {
          action: {
            location: location.href,
            destination
          },
          industry: industryHierarchy,
          ingest_version: 1,
          ingest_version_date: "2025-02-05"
        }
      }
    };
  },

  /**
   * 日経NEEDS業界解説レポート
   *
   * @param {string} type ファイル形式（PPT,WORD等）
   * @param {string} file_type レポートの種類を設定
   * @param {string} file_name ファイル名（～.ppt等）
   * @param {string} title レポートの見出し
   */
  downloadVsNeedsIndustryExplainReportComplete: ({
    type,
    fileType,
    fileName,
    title
  }: {
    type: string;
    fileType: string;
    fileName: string;
    title: string;
  }) => {
    return {
      action: "download",
      category: "system",
      events: "ファイルダウンロード",
      obj: {
        custom_vars: {
          url: location.href,
          type: type,
          file_type: fileType,
          file_name: fileName,
          title,
          ingest_version: 1,
          ingest_version_date: "2025-02-05"
        }
      }
    };
  },

  /**
   * B2B_NVS_FR_2018-5730
   * B、②「閲覧する」ボタン（修正）
   * clickイベント
   */
  clickPayByAmountView: () => {
    return {
      action: "click",
      category: "button",
      events: "従量課金コンテンツ閲覧",
      obj: {
        custom_vars: {
          ingest_version: 1,
          ingest_version_date: "2025-02-18"
        }
      }
    };
  },

  /**
   * B2B_NVS_FR_2018-5730
   * B、②「閲覧する」ボタン（修正）
   * downloadイベント
   */
  downloadFileOnPayByAmount: ({
    type,
    fileType,
    fileName,
    title
  }: {
    type: string;
    fileType: string;
    fileName: string;
    title: string;
  }) => {
    return {
      action: "download",
      category: "system",
      events: "ファイルダウンロード",
      obj: {
        custom_vars: {
          type,
          file_type: fileType,
          file_name: fileName,
          title,
          ingest_version: 1,
          ingest_version_date: "2025-02-18"
        }
      }
    };
  },

  /**
   * サンプルダウンロード
   */
  downloadSample: () => {
    return {
      action: "click",
      category: "button",
      events: "サンプルダウンロード",
      obj: {
        custom_vars: {
          ingest_version: 1,
          ingest_version_date: "2025-02-18"
        }
      }
    };
  }
};

export type Actions = typeof actions;
export type ActionName = keyof Actions;
export type Options<K extends ActionName> = Parameters<Actions[K]>[0];
