import { Reducer } from "redux";
import produce from "immer";
import { MultipleAction } from "./actions";
import {
  NeedsMstL,
  NeedsMstM,
  NeedsMstS,
  Company,
  DisplayItem,
  CorpName,
  CompaniesDataName,
  CompaniesDataValue
} from "models/Multiple";
import { formatDateToYMDStr } from "utils/helper/format";
import { cloneDeep } from "lodash";

export const dispFormat = {
  vertical: "01",
  horizontal: "02"
};
export const dataKind = {
  result: 1,
  nikkei_forecast: 2
};
export type PeriodAxis = keyof typeof dispFormat;
export type ReferenceDataType = keyof typeof dataKind;
export type PeriodType =
  | "all"
  | "free"
  | "today"
  | "twoDay"
  | "twoWeek"
  | "oneMonth"
  | "twoMonth"
  | "threeMonth"
  | "one";
export type TermType = PeriodType;

interface MultipleState {
  condition: ConditionState;
  result: ResultState;
}

interface ConditionState {
  corpSelect: CorpSelectState;
  periodAxis: PeriodAxis;
  referenceDataType: ReferenceDataType;
  periodType: PeriodType;
  fromDate?: string;
  toDate?: string;
  displayItems: DisplayItem[];
  isComponentsDisplayed: boolean;
  isDisplayItemsLoading: boolean;
}

interface ResultState {
  corpNames: CorpName[];
  dates: string[];
  companiesDataNames: CompaniesDataName[];
  companiesDataValues: CompaniesDataValue[];
  periodAxis?: "vertical" | "horizontal";
  isSearchResultsLoading: boolean;
  updateDate: {
    to?: string;
    from?: string;
  };
  itemIds?: string;
  components?: boolean;
  isSeached: boolean;
  periodType?: PeriodType;
}

export interface CorpSelectState {
  // 企業
  corps: string[];
  // 一括ニュース・関連記事設定の項目（メディア名）
  media: string[];
  // 一括ニュース・関連記事設定 選択されたメディア
  checkedMedia: string[];
  // 選択された企業
  checkedCompanyCodes: string[];
  // 業界大分類
  needsMstL: NeedsMstL[];
  // 業界中分類
  needsMstM: NeedsMstM[];
  // 業界小分類
  needsMstS: NeedsMstS[];
  // （業界分類から取得した）企業
  companyList: Company[];
  // 企業名からの検索結果
  searchResult?: string;
  // 企業名からの検索 ローディング表示flag
  isLoadingSearch: boolean;
  // 最近見た企業
  recentList: Company[];
  // 選択されたブックマークフォルダのID
  selectedFolderId: string;
  // ブックマークフォルダ
  bookmarkFolderList: string[];
  // ブックマーク
  bookmarkList: string[];
  selectedCorpList: Company[];
  corpListBySuggest: Company[] | null;
  corpList: Company[];
}

const date = new Date();
const toDate = cloneDeep(date);
const oneYearAgo = new Date(date.setMonth(date.getMonth() - 1));

const initialState: MultipleState = {
  condition: {
    corpSelect: {
      corps: [], // 企業
      media: [], // 一括ニュース・関連記事設定の項目（メディア名）
      checkedMedia: [], // 一括ニュース・関連記事設定 選択されたメディア
      checkedCompanyCodes: [], // 選択された企業
      needsMstL: [], // 業界大分類
      needsMstM: [], // 業界中分類
      needsMstS: [], // 業界小分類
      companyList: [], // （業界分類から取得した）企業
      searchResult: undefined, // 企業名からの検索結果
      isLoadingSearch: false, // 企業名からの検索 ローディング表示flag
      recentList: [], // 最近見た企業
      selectedFolderId: "", // 選択されたブックマークフォルダのID
      bookmarkFolderList: [], // ブックマークフォルダ
      bookmarkList: [], // ブックマーク
      selectedCorpList: [],
      corpListBySuggest: null,
      corpList: []
    },
    periodAxis: "vertical",
    referenceDataType: "result",
    periodType: "oneMonth",
    fromDate: formatDateToYMDStr(oneYearAgo),
    toDate: formatDateToYMDStr(toDate),
    displayItems: [],
    isComponentsDisplayed: false,
    isDisplayItemsLoading: false
  },
  result: {
    corpNames: [],
    dates: [],
    companiesDataNames: [],
    companiesDataValues: [],
    periodAxis: undefined,
    isSearchResultsLoading: false,
    updateDate: {
      to: undefined,
      from: undefined
    },
    itemIds: undefined,
    components: undefined,
    isSeached: false,
    periodType: "oneMonth"
  }
};

export const toolMultiple: Reducer<MultipleState, MultipleAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "tool/multiple/corpSelect/SET_NEED_MST_L":
      return produce(state, draft => {
        draft.condition.corpSelect.needsMstL = action.payload;
      });
    case "tool/multiple/corpSelect/SET_NEED_MST_M":
      return produce(state, draft => {
        draft.condition.corpSelect.needsMstM = action.payload;
      });
    case "tool/multiple/corpSelect/SET_NEED_MST_S":
      return produce(state, draft => {
        draft.condition.corpSelect.needsMstS = action.payload;
      });
    case "tool/multiple/corpSelect/SET_COMPANY_LIST":
      return produce(state, draft => {
        draft.condition.corpSelect.companyList = action.payload;
      });
    case "tool/multiple/corpSelect/ADD_SELECTED_CORPS":
      return produce(state, draft => {
        draft.condition.corpSelect.selectedCorpList = action.payload;
      });
    case "tool/multiple/corpSelect/SET_COMPANY_LIST_BY_SUGGEST":
      return produce(state, draft => {
        draft.condition.corpSelect.corpListBySuggest = action.payload;
      });
    case "tool/multiple/corpSelect/SET_RECENT_COMPANY_LIST":
      return produce(state, draft => {
        draft.condition.corpSelect.recentList = action.payload;
      });
    case "tool/multiple/corpSelect/REMOVE_SELECTED_CORP":
      return produce(state, draft => {
        draft.condition.corpSelect.selectedCorpList = state.condition.corpSelect.selectedCorpList.filter(
          (corp: Company) => corp.companyCode !== action.payload.companyCode
        );
      });
    case "tool/multiple/corpSelect/REMOVE_ALL_SELECTED_CORPS":
      return produce(state, draft => {
        draft.condition.corpSelect.selectedCorpList = [];
      });
    case "tool/multiple/SET_PERIOD_AXIS":
      return produce(state, draft => {
        draft.condition.periodAxis = action.payload;
      });
    case "tool/multiple/SET_REFERENCE_DATA_TYPE":
      return produce(state, draft => {
        draft.condition.referenceDataType = action.payload;
      });
    case "tool/multiple/SET_PERIOD_TYPE":
      return produce(state, draft => {
        draft.condition.periodType = action.payload;
      });
    case "tool/multiple/SET_FROM_DATE":
      return produce(state, draft => {
        draft.condition.fromDate = action.payload || "";
      });
    case "tool/multiple/SET_TO_DATE":
      return produce(state, draft => {
        draft.condition.toDate = action.payload || "";
      });
    case "tool/multiple/CLEAR_SEARCH_CONDITION":
      return produce(state, draft => {
        draft.condition = {
          ...initialState.condition,
          displayItems: state.condition.displayItems.map((i: DisplayItem) => ({
            ...i,
            checked: true
          }))
        };
      });
    case "tool/multiple/SET_SEARCH_RESULT_CORP_NAMES":
      return produce(state, draft => {
        draft.result.corpNames = action.payload;
      });
    case "tool/multiple/SET_SEARCH_RESULT_DATES":
      return produce(state, draft => {
        draft.result.dates = action.payload;
      });
    case "tool/multiple/ADD_SEARCH_RESULT_DATA_NAMES":
      return produce(state, draft => {
        draft.result.companiesDataNames = [
          ...state.result.companiesDataNames,
          ...action.payload
        ];
      });
    case "tool/multiple/SET_SEARCH_RESULT_COMPANIES_DATA_RECORDS":
      return produce(state, draft => {
        draft.result.companiesDataValues = action.payload;
      });
    case "tool/multiple/SET_SEARCH_RESULT_PERIOD_AXIS":
      return produce(state, draft => {
        draft.result.periodAxis = action.payload;
      });
    case "tool/multiple/SET_SEARCH_RESULT_UPDATE_DATE":
      return produce(state, draft => {
        draft.result.updateDate = action.payload;
      });
    case "tool/multiple/SET_SEARCH_RESULT_ITEM_IDS":
      return produce(state, draft => {
        draft.result.itemIds = action.payload;
      });
    case "tool/multiple/SET_SEARCH_RESULT_COMPONENTS":
      return produce(state, draft => {
        draft.result.components = action.payload;
      });
    case "tool/multiple/SET_SEARCH_RESULT_PERIOD_TYPE":
      return produce(state, draft => {
        draft.result.periodType = action.payload;
      });
    case "tool/multiple/TOGGLE_DISPLAY_ITEM_CHECK":
      return produce(state, draft => {
        draft.condition.displayItems = state.condition.displayItems.map(
          (i: DisplayItem) => {
            return action.payload === i.id ? { ...i, checked: !i.checked } : i;
          }
        );
      });
    case "tool/multiple/SET_DISPLAY_ITEMS_CHECK":
      const { ids, checked } = action.payload;
      return produce(state, draft => {
        draft.condition.displayItems = state.condition.displayItems.map(
          (i: DisplayItem) => {
            return ids.includes(i.id) ? { ...i, checked: checked } : i;
          }
        );
      });
    case "tool/multiple/SET_DISPLAY_ITEMS":
      return produce(state, draft => {
        draft.condition.displayItems = action.payload;
      });
    case "tool/multiple/TOGGLE_DISPLAY_COMPONENTS_CHECK":
      return produce(state, draft => {
        draft.condition.isComponentsDisplayed = !state.condition
          .isComponentsDisplayed;
      });
    case "tool/multiple/SET_IS_SEARCH_RESULTS_LOADING":
      return produce(state, draft => {
        draft.result.isSearchResultsLoading = action.payload;
      });
    case "tool/multiple/SET_IS_DISPLAY_ITEMS_LOADING":
      return produce(state, draft => {
        draft.condition.isDisplayItemsLoading = action.payload;
      });
    case "tool/multiple/SET_IS_SEARCHED":
      return produce(state, draft => {
        draft.result.isSeached = action.payload;
      });
    case "tool/multiple/CLEAR_SEARCH_RESULT":
      return produce(state, draft => {
        draft.result = initialState.result;
      });
    default:
      return state;
  }
};
