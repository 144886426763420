import React, { useRef, useState } from "react";
import styles from "./style.scss";
import IconImage from "images/icon/arrow_right_circle.svg";
import { CorporationTable } from "../../CorporationTable";
import { isEmpty } from "utils/helper/common";
import LoadingSpinner from "components/Common/LoadingSpinner";
import { RawInput } from "features/RawInput";

interface Collection {
  binderId: string;
  caption: string;
}

interface Corp {
  companyCode: string;
  companyName: string;
  stkCode: string;
  source: string;
  companyKbn?: string;
  dataExistsFlg?: boolean;
}

interface Props {
  collections: Collection[];
  corporations: Corp[];
  selectedCorp: Corp[];
  maxSelectableNumber: number;
  isLoading: boolean;
  onSelectCollection: (id: string) => void;
  onSelectCorp: (corps: Corp[]) => void;
  keyword: string;
  setKeyword: (keyword: string) => void;
  onKeywordSearch: (keyword?: string) => void;
  isBinderLoading: boolean;
  selectedId: string;
  disabledCodes?: string[];
}

export const Collection: React.FC<Props> = ({
  collections,
  corporations,
  selectedCorp,
  maxSelectableNumber,
  isLoading,
  onSelectCollection,
  onSelectCorp,
  keyword,
  setKeyword,
  onKeywordSearch,
  isBinderLoading,
  selectedId,
  disabledCodes = []
}) => {
  const isInit = useRef(true);
  const list = corporations.filter(item => item.dataExistsFlg === true);
  const [keywordError, setKeywordError] = useState<string>("");
  const [isKeyword, setIsKeyword] = useState<boolean>(false);

  const onSearch = (event: React.KeyboardEvent<Element>) => {
    if (event.key === "Enter") {
      handleSearch(keyword);
    }
  };
  const handleSearch = (keyword: string) => {
    if (keyword) {
      setIsKeyword(true);
    } else {
      setIsKeyword(false);
    }
    onKeywordSearch(keyword);
  };
  const onValidateKeywords = (rawText: string) => {
    const maxLength = 500;
    if (rawText.length > maxLength) {
      setKeywordError(`入力可能な文字数(${maxLength})を超えています`);
    } else {
      setKeywordError("");
    }
    setKeyword(rawText);
  };

  // コレクションが1件も存在しない場合、それを伝える文言を表示する
  if (!isBinderLoading && !isKeyword && collections.length === 0) {
    return (
      <div className={styles.emptyCollection}>
        表示可能なコレクションがありません
      </div>
    );
  }

  return (
    <>
      <div className={styles.searchWrapper}>
        <RawInput
          rawText={keyword}
          onChangeRawText={rawText => {
            onValidateKeywords(rawText);
          }}
          placeholder="キーワードで絞り込み"
          addonPrepend={<span className={styles.searchIcon} />}
          addonAppend={
            keyword && (
              <span
                className={styles.closeIcon}
                onClick={() => {
                  setKeyword("");
                  setKeywordError("");
                  handleSearch("");
                }}
              />
            )
          }
          className={styles.rawInput}
          onKeyDown={event => !keywordError && onSearch(event)}
        />
        <div className={styles.errorMsg}>{keywordError}</div>
      </div>
      <div className={styles.container}>
        <div className={styles.collections}>
          {isBinderLoading && (
            <div className={styles.loading}>
              <LoadingSpinner size="L" />
            </div>
          )}
          {!isBinderLoading && collections.length > 0 && (
            <div className={styles.title}>コレクション名</div>
          )}
          {!isBinderLoading &&
            collections.map((collection, index) => (
              <div
                className={`${styles.row} ${
                  selectedId === collection.binderId ? styles.rowSelected : ""
                }`}
                key={`collection-${collection.binderId}-${index}`}
                onClick={() => {
                  onSelectCollection(collection.binderId);
                  isInit.current = false;
                }}
              >
                {collection.caption}
              </div>
            ))}
          {!isBinderLoading && isKeyword && collections.length === 0 && (
            <div className={styles.emptySearchResult}>
              <div>ご指定の条件に該当する情報が見つかりませんでした。</div>
              <div>条件を変更して再度ご検索ください。</div>
            </div>
          )}
        </div>
        <div className={styles.arrow}>
          <div className={styles.icon}>
            <img src={IconImage} />
          </div>
        </div>
        <div className={styles.corporations}>
          {isEmpty(corporations) || isLoading ? (
            <div className={styles.emptyContainer}>
              {isLoading ? (
                <div className={styles.loading}>
                  <LoadingSpinner size="L" />
                </div>
              ) : (
                !isInit.current &&
                !!selectedId && (
                  <div
                    className={styles.emptyComponent}
                    data-testid="Common-EmptyView"
                  >
                    <div>利用できるデータが登録されていません</div>
                  </div>
                )
              )}
            </div>
          ) : (
            <CorporationTable
              items={list}
              selectedCorp={selectedCorp}
              maxSelectableNumber={maxSelectableNumber}
              onSelect={onSelectCorp}
              disabledCodes={disabledCodes}
            />
          )}
        </div>
      </div>
    </>
  );
};
