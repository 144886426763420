import React, { Component } from "react";
import styles from "./style.scss";
import commonStyles from "../../Common/style.scss";
import DownloadButton from "components/Common/Button/Download";

import { isEmpty } from "utils/helper/common";
import { download } from "utils/helper/download";
import { renderSub } from "components/Common/Popup/TrendReport/Common/render";
import fileDownload from "components/Common/FileDownload/enhance";
import { Link, withRouter } from "react-router-dom";
import {
  corpIndustryKbn,
  loadCorporationChildPagePaths,
  parseQuery
} from "utils/helper/url";
import BookmarkBalloon from "components/Common/BookmarkBalloon";
import PaidConfirmModal from "features/Modal/PaidConfirm";
import TextButton from "components/Common/Button/Text";
import { trackAction } from "utils/helper/analytics";
import { openPopup } from "components/Common/Popup/Link";
import cn from "classnames";
import SectionLoading from "components/Common/SectionLoading";

class Usual extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resourceId: props.resourceId,
      visiblePaidConfirm: false,
      paidType: ""
    };
    this.clickDownloadHandler = this.clickDownloadHandler.bind(this);
    this.clickBodyHandler = this.clickBodyHandler.bind(this);
    this.pdfClickHandler = this.pdfClickHandler.bind(this);
    this.xlsClickHandler = this.xlsClickHandler.bind(this);
    this.xbrlClickHandler = this.xbrlClickHandler.bind(this);
    this.attachClickHandler = this.attachClickHandler.bind(this);
    this.trackClickCanonicalUrl = this.trackClickCanonicalUrl.bind(this);
    this.pptClickHandler = this.pptClickHandler.bind(this);
  }

  async clickDownloadHandler(key, type, title, fileType) {
    // ダウンロード中であれば処理しない
    if (!isEmpty(this.props.downloadResourceId)) return;

    const resourceId = `${this.state.resourceId}_${type}`;
    this.props.startDownload(resourceId);

    switch (type) {
      case "pdf":
        await this.pdfClickHandler(key, title, fileType);
        break;
      case "xls":
        await this.xlsClickHandler(key);
        break;
      case "xbrl":
        await this.xbrlClickHandler(key);
        break;
      case "document":
        await this.attachClickHandler(key);
        break;
      case "ppt":
        await this.pptClickHandler(key);
        break;
    }
    // DL アニメーションする resourceId を解除
    this.props.stopDownload();
  }

  clickBodyHandler() {
    const search = new URLSearchParams(this.props.location.search);
    search.delete("mediaFeeType");
    const query = parseQuery(decodeURIComponent(search.toString()));
    openPopup({ query });
  }

  async pdfClickHandler(key, title, fileType) {
    const searchParams = new URLSearchParams(this.props.location.search);
    const viewer = searchParams.get("pdfViewer");
    const downloadPdfKey = this.props.downloadPdfKey || "pdf";

    await download(
      {
        key:
          downloadPdfKey === "pdf" && this.props.mediaFeeType === "0"
            ? "freePdf"
            : downloadPdfKey,
        params: {
          ...this.props.corpParams,
          keyPdf: key,
          Pdfkey: key,
          pdfKey: key
        },
        type: "payByAmount"
      },
      this.props.pathname,
      viewer === "true"
        ? {
            viewUrl: "/pdf-viewer",
            keywords: searchParams.get("keywords").split(",")
          }
        : undefined,
      "PDF",
      fileType,
      "",
      title
    ).catch(error => {
      // 401 であれば再ログイン要求
      if (error.body == "UNAUTHORIZED") this.props.needAuthenticate();
      // それ以外は通常のトースト表示
      else this.props.setToastErrors(error.message);
    });
  }

  async xlsClickHandler(key) {
    await download(
      {
        key: isEmpty(this.props.downloadXlsKey)
          ? "xls"
          : this.props.downloadXlsKey,
        params: {
          ...this.props.corpParams,
          keyText: key
        }
      },
      this.props.pathname
    ).catch(error => {
      // 401 であれば再ログイン要求
      if (error.body == "UNAUTHORIZED") this.props.needAuthenticate();
      // それ以外は通常のトースト表示
      else this.props.setToastErrors(error.message);
    });
  }

  async xbrlClickHandler(key) {
    await download(
      {
        key: isEmpty(this.props.downloaXbrlKey)
          ? "xbrl"
          : this.props.downloaXbrlKey,
        params: {
          ...this.props.corpParams,
          keyBinaly: key
        }
      },
      this.props.pathname
    ).catch(error => {
      // 401 であれば再ログイン要求
      if (error.body == "UNAUTHORIZED") this.props.needAuthenticate();
      // それ以外は通常のトースト表示
      else this.props.setToastErrors(error.message);
    });
  }

  async attachClickHandler(key) {
    await download(
      {
        key: isEmpty(this.props.downloadAttachKey)
          ? "attach"
          : this.props.downloadAttachKey,
        params: {
          ...this.props.corpParams,
          keyBinaryAttach: key
        }
      },
      this.props.pathname
    ).catch(error => {
      // 401 であれば再ログイン要求
      if (error.body == "UNAUTHORIZED") this.props.needAuthenticate();
      // それ以外は通常のトースト表示
      else this.props.setToastErrors(error.message);
    });
  }

  async pptClickHandler(key) {
    const params = {
      key: key,
      keyBinaly: key,
      keyBinaryAttach: key
    };
    await download({ params, key: "ppt", type: "ppt" }, "/binder/article", {
      fileType: "業界分析レポート"
    }).catch(error => {
      // 401 であれば再ログイン要求
      if (error.body == "UNAUTHORIZED") this.props.needAuthenticate();
      // それ以外は通常のトースト表示
      else this.props.setToastErrors(error.message);
    });
  }

  renderTable(datas) {
    // 企業リンクの生成
    const buildCropUrl = details => {
      // 空か上場企業でなければスキップ
      if (
        isEmpty(details) ||
        !details.some(detail => detail.header === "日経会社コード")
      )
        return false;

      const search = new URLSearchParams();
      search.append("corpIndustyKbn", corpIndustryKbn.domestic);
      for (const key in details) {
        switch (details[key].header) {
          case "日経会社コード":
            search.append("corpIndustyCode", details[key].data);
            break;
          case "corpType":
            search.append("corpType", details[key].data);
            break;
          case "asrf":
            search.append("asrf", details[key].data);
            break;
        }
      }

      const paths = loadCorporationChildPagePaths(
        "corporation",
        "basics",
        corpIndustryKbn.domestic
      ); // 企業サマリのパス

      return `${paths[0].path}?${search}`;
    };

    const colNum = 3;
    const element = (
      <table className={styles.vsTableHasLink}>
        <tbody>
          {datas.map((data, index) => {
            const dataDetails = data.details.filter(detail => {
              return detail.header !== "corpType" && detail.header !== "asrf";
            });
            const url = buildCropUrl(data.details);
            return (
              <React.Fragment key={index}>
                <tr>
                  <th
                    className={styles.header}
                    rowSpan={dataDetails.length + 1}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#eaf1f5" : "#dee8ef"
                    }}
                  >
                    <span className={styles.fulfilledCell}>{data.header}</span>
                  </th>
                  <td colSpan={colNum - 1}>
                    {url ? (
                      <Link
                        to={url}
                        className={styles.fulfilledCell}
                        target="_blank"
                      >
                        {data.data}
                      </Link>
                    ) : (
                      <span className={styles.fulfilledCell}>{data.data}</span>
                    )}
                  </td>
                </tr>
                {!isEmpty(dataDetails) &&
                  dataDetails.map((detail, index) => {
                    return (
                      <tr key={index}>
                        <td className={styles.header}>
                          <span className={styles.fulfilledCell}>
                            {detail.header}
                          </span>
                        </td>
                        <td>
                          {url && detail.header === "株式コード" ? (
                            <Link
                              to={url}
                              className={styles.fulfilledCell}
                              target="_blank"
                            >
                              {detail.data}
                            </Link>
                          ) : (
                            <span className={styles.fulfilledCell}>
                              {detail.data}
                            </span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    );

    return element;
  }

  renderInvestmentsList(list) {
    return (
      <div className={styles.investments}>
        <table className={styles.vsTable}>
          <thead>
            <tr>
              <th>株式コード</th>
              <th>会社名</th>
              <th>決算期</th>
              <th>持株数（千株）</th>
              <th>所有割合（%）</th>
            </tr>
          </thead>
          <tbody>
            {list.map((item, index) => (
              <tr key={index}>
                <td>{item.stkcode}</td>
                <td>{item.companyName}</td>
                <td>{item.acc}</td>
                <td>{item.count}</td>
                <td>{item.ratio}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  renderMeta(sponserName) {
    let keys = [];
    sponserName && sponserName !== ""
      ? (keys = [
          "printedDate",
          "displayTime",
          "sponserName",
          "pageFromTo",
          "keyPdf",
          "keyPpt"
        ])
      : (keys = [
          "printedDate",
          "displayTime",
          "mediaName",
          "pageFromTo",
          "characterCount",
          "keyBodyFare",
          "keyPdf",
          "keyPpt"
        ]);
    const props = this.props;

    return keys.map(key => {
      if (isEmpty(props[key])) return;
      if (key === "printedDate" || key === "displayTime") {
        const printedDate = props[key].trim().substr(0, 10);
        return (
          <span key={key} className={styles.data}>
            {printedDate}
          </span>
        );
      }

      if (key === "characterCount") {
        let characterCountDisp = "";
        const re = /文字$/;
        if (props[key].match(re)) {
          characterCountDisp = props[key];
        } else if (!props[key].match(re) && props.keyText == "1") {
          characterCountDisp = `全${props.characterCount}件`;
        } else {
          characterCountDisp = `${props.characterCount}文字`;
        }
        return (
          <span key={key} className={styles.data}>
            {characterCountDisp}
          </span>
        );
      }

      if (key === "keyBodyFare") {
        // 0円の場合と本文画面では表示しない
        if (
          props.keyBodyFare === "0" ||
          props.body != null ||
          props.bodyFlg !== "1"
        )
          return;
        return (
          <span key={key} className={styles.fares}>
            本文 {props.keyBodyFare}円
          </span>
        );
      }

      if (key === "keyPdf") {
        if (
          props.keyPdfFare != "0" &&
          (props.bodyFlg === "1" ||
            props.keyBodyFare === "0" ||
            props.keyBodyFare === "")
        ) {
          return (
            <span key={key} className={styles.fares}>
              PDF {props.keyPdfFare}円
            </span>
          );
        } else {
          return (
            <span key={key} className={styles.data}>
              PDF有
            </span>
          );
        }
      }

      if (key === "keyPpt") {
        return (
          <span key={key} className={styles.data}>
            PPT有
          </span>
        );
      }

      return (
        <span key={key} className={styles.data}>
          {props[key]}
        </span>
      );
    });
  }

  renderBody(body, bodySplitText, isMobile) {
    if (isMobile && body) {
      const regex = /( |")(width:\d+px)/;
      body = body.replace(regex, "");
    }

    // body に直接 Table が含まれているパターンを考慮
    // ex. 企業/雑誌・レポート => 矢野経済研究所マーケットシェア事典
    if (!isEmpty(body) && /\<\/[^\>]+\>/.test(body)) {
      return (
        <div
          className={styles.bodyInTable}
          dangerouslySetInnerHTML={{ __html: body }}
        />
      );
    }
    return (
      <div className={styles.body}>
        {!isEmpty(bodySplitText) ? bodySplitText : body}
      </div>
    );
  }

  renderDownloadButtons() {
    if (
      isEmpty(this.props.keyPdf) &&
      isEmpty(this.props.keyText) &&
      isEmpty(this.props.keyBinaly) &&
      isEmpty(this.props.keyBinaryAttach) &&
      isEmpty(this.props.keyPpt)
    ) {
      return null;
    }
    return (
      <ul className={styles.downloadButtons}>
        {this.props.keyPdf && (
          <li>
            <DownloadButton
              type="pdf"
              onClick={() => {
                if (
                  (this.props.keyPdfFare &&
                    Number(this.props.keyPdfFare.replace(/,/g, "")) > 0 &&
                    this.props.paymentConfirmFlg === "1") ||
                  (!isEmpty(this.props.keyPdfFare) &&
                    Number(this.props.keyPdfFare.replace(/,/g, "")) > 0 &&
                    this.props.forcedPaidConfirmFlg === "1")
                ) {
                  this.setState({
                    visiblePaidConfirm: true,
                    paidType: "download"
                  });
                  return;
                }

                this.clickDownloadHandler(
                  this.props.keyPdf,
                  "pdf",
                  this.props.headline,
                  this.props.mediaName
                );
              }}
              isDownloading={
                this.props.downloadResourceId === `${this.state.resourceId}_pdf`
              }
            />
          </li>
        )}
        {this.props.keyPpt && (
          <li>
            <DownloadButton
              type="ppt"
              onClick={() => {
                this.clickDownloadHandler(this.props.keyPpt, "ppt");
              }}
              isDownloading={
                this.props.downloadResourceId === `${this.state.resourceId}_pdf`
              }
            />
          </li>
        )}
        {this.props.keyText && (
          <li>
            <DownloadButton
              type="xls"
              onClick={() =>
                this.clickDownloadHandler(this.props.keyText, "xls")
              }
              isDownloading={
                this.props.downloadResourceId === `${this.state.resourceId}_xls`
              }
            />
          </li>
        )}
        {this.props.keyBinaly && (
          <li>
            <DownloadButton
              type="xbrl"
              onClick={() =>
                this.clickDownloadHandler(this.props.keyBinaly, "xbrl")
              }
              isDownloading={
                this.props.downloadResourceId ===
                `${this.state.resourceId}_xbrl`
              }
            />
          </li>
        )}
        {this.props.keyBinaryAttach && (
          <li>
            <DownloadButton
              type="document"
              onClick={() =>
                this.clickDownloadHandler(
                  this.props.keyBinaryAttach,
                  "document"
                )
              }
              isDownloading={
                this.props.downloadResourceId ===
                `${this.state.resourceId}_document`
              }
            />
          </li>
        )}
      </ul>
    );
  }

  trackClickCanonicalUrl(mediaCode, sponsorName) {
    trackAction("clickCanonicalUrl", {
      mediaCode,
      sponsorName
    });
  }

  render() {
    const props = this.props;

    // htmlタグの表示取り扱い(bタグ)
    let bodySplitText;
    if (!isEmpty(props.bodySplit)) {
      bodySplitText = props.bodySplit.map((item, index) =>
        props.bodyHighlight.indexOf(index.toString()) < 0 ? (
          <span key={index}>{item}</span>
        ) : (
          <span key={index} className={styles.bold}>
            {item}
          </span>
        )
      );
    }

    const searchParams = new URLSearchParams(props.location.search);
    const isShowBookmarkButton =
      searchParams.has("articleId") ||
      searchParams.has("kijiId") ||
      searchParams.has("keyBody");

    return (
      <div className={commonStyles.component} data-testid="Common-Popup-Usual">
        <div
          className={
            props.isMobilePage && props.isMobileSize
              ? commonStyles.mobileMain
              : commonStyles.main
          }
        >
          {props.isPopupLoading && (
            <SectionLoading isLoading={props.isPopupLoading} />
          )}
          <div className={styles.metaWrapper}>
            {!(props.isMobilePage && props.isMobileSize) && (
              <div className={styles.buttonWrapper}>
                {props.mediaCode === "WC1" ? (
                  <BookmarkBalloon
                    itemType="government"
                    size="small"
                    articleKbn={props.articleKbn}
                    mediaCode={props.mediaCode}
                    mediaName={props.mediaName}
                    className={styles.bookmarkBalloon}
                    kijiId={props.keyBody}
                  />
                ) : (
                  isShowBookmarkButton &&
                  props.mediaFeeType === "0" && (
                    <BookmarkBalloon
                      itemType="article"
                      size="small"
                      articleKbn={props.articleKbn}
                      mediaCode={props.mediaCode}
                      mediaName={props.mediaName}
                      className={styles.bookmarkBalloon}
                    />
                  )
                )}
              </div>
            )}
          </div>
          <div
            className={
              props.isMobileSize && props.isMobilePage
                ? styles.mobileMeta
                : styles.meta
            }
          >
            {this.renderMeta(props.sponserName)}
          </div>
          <div className={styles.header}>
            <h1
              className={
                props.isMobileSize && props.isMobilePage
                  ? commonStyles.mobileHeadingLev1
                  : commonStyles.headingLev1
              }
            >
              {props.headline || props.title}
            </h1>
          </div>
          {this.renderDownloadButtons()}
          <div
            className={cn(
              commonStyles.bodyContainer,
              props.isMobileSize &&
                props.isMobilePage &&
                commonStyles.mobileBodyContainer
            )}
          >
            {/* ボディ(テキストのみ)レンダリング */}
            {!isEmpty(props.body) && props.body.startsWith("<thead>") ? (
              <div
                className={styles.bodyTable}
                dangerouslySetInnerHTML={{ __html: props.body }}
              />
            ) : (
              this.renderBody(
                props.body,
                bodySplitText,
                props.isMobileSize && props.isMobilePage
              )
            )}
            {/* テーブル系レンダリング */}
            {!isEmpty(props.reportStrategyDatas) &&
              this.renderTable(props.reportStrategyDatas)}
            {/* 大株主の投資先一覧 */}
            {props.investmentsList !== undefined &&
              this.renderInvestmentsList(props.investmentsList)}
            {props.bodyFlg === "1" && (
              <div className={styles.previewContainer}>
                <div>本文テキストの表示はありません。</div>
                <div>
                  「続きを読む」をクリックで別ウインドウで表示されます。
                </div>
              </div>
            )}
          </div>
          {props.bodyFlg === "1" && (
            <div className={styles.bottomContainer}>
              <div className={styles.bottomButton}>
                <TextButton
                  onClick={() => {
                    if (
                      this.props.paymentConfirmFlg === "1" ||
                      this.props.forcedPaidConfirmFlg === "1"
                    ) {
                      this.setState({
                        visiblePaidConfirm: true,
                        paidType: "body"
                      });
                      return;
                    }
                    this.clickBodyHandler();
                  }}
                >
                  続きを読む
                </TextButton>
              </div>
            </div>
          )}
          {props.canonicalUrl && props.canonicalUrl !== "" && (
            <div className={styles.bottomContainer}>
              <div className={styles.bottomButton}>
                <TextButton
                  onClick={() => {
                    this.trackClickCanonicalUrl(
                      props.mediaCode,
                      props.sponserName
                    );
                    window.open(props.canonicalUrl);
                  }}
                >
                  外部サイトで続きを読む
                </TextButton>
              </div>
            </div>
          )}
        </div>
        {/* 企業タグのみ表示・業界タグは不要（null 渡し） */}
        {props.companyInfoList &&
          !(props.isMobilePage && props.isMobileSize) &&
          renderSub({
            industryInfo: null,
            companyInfo: props.companyInfoList
          })}
        {this.state.visiblePaidConfirm && (
          <PaidConfirmModal
            headline={props.headline || props.title}
            fare={
              this.state.paidType === "body"
                ? `本文:${this.props.keyBodyFare}円`
                : `PDF：${this.props.keyPdfFare}円`
            }
            samplePath={this.props.samplePath}
            open={() => {
              this.state.paidType === "body"
                ? this.clickBodyHandler()
                : this.clickDownloadHandler(
                    this.props.keyPdf,
                    "pdf",
                    this.props.headline,
                    this.props.mediaName
                  );
            }}
            onClose={() =>
              this.setState({ visiblePaidConfirm: false, paidType: "" })
            }
          />
        )}
      </div>
    );
  }
}

export default withRouter(fileDownload(Usual));
